import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Duration, hashCode } from '@idk-web/core-utils';
import {
  useAsync,
  AsyncState,
  useLocalStorage,
  Styling,
  Box,
  OkButton,
} from '@idk-web/core-ui';
import { getScheduledMaintenance } from '@idk-web/api';

const Container = styled(Box).attrs(({ theme }) => ({
  alignX: 'center',
  spacing: theme.spacing[2],
}))`
  position: relative;
`;

const Content = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[2],
}))`
  padding: ${Styling.spacing(2)};
  border: 1px dashed ${({ theme }) => theme.palette.grayscale.light2};
  background-color: ${({ theme }) => theme.palette.grayscale.light5};
  text-align: center;
`;

const Header = styled.div`
  ${Styling.typography('h4')};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const Text = styled.div`
  ${Styling.typography('body')};
  color: ${({ theme }) => theme.palette.primary.text};
`;

type MaintenanceDismissal = {
  timestamp: number;
  hashcode: number;
};

const ScheduledMaintenanceMessage: FC = () => {
  const { t } = useTranslation();
  const maintenance = useAsync(getScheduledMaintenance, []);
  const [open, setOpen] = useState<boolean | null>(null); // null = not open, false = closed by user, true = open
  const storage = useLocalStorage('maintenance_dismissal');

  useEffect(() => {
    if (maintenance.state === AsyncState.SUCCESS && maintenance.value) {
      let shouldOpen = false;

      if (storage.value) {
        let dismissal: MaintenanceDismissal;
        try {
          dismissal = JSON.parse(storage.value) as MaintenanceDismissal;
        } catch (e) {
          console.error('Failed to parse maintenance history', e);
          storage.clear();
          return;
        }

        const duration = Duration.betweenTimestamps(
          getTimestamp(),
          dismissal.timestamp,
          ['days'],
        );

        if (duration.days > 0) {
          shouldOpen = true;
        }

        if (dismissal.hashcode !== getHashCode()) {
          shouldOpen = true;
        }
      } else {
        shouldOpen = true;
      }

      if (shouldOpen) {
        storage.clear();
        setOpen(true);
      }
    }
  }, [maintenance]);

  useEffect(() => {
    if (open === false) {
      return;
    }

    const id = setInterval(maintenance.reload, 15 * 60 * 1000);

    return () => clearInterval(id);
  }, [open]);

  const getTimestamp = (): number => Date.now() / 1000;

  const getHashCode = (): number =>
    hashCode(maintenance.value!.subject + maintenance.value!.body);

  const handleClose = () => {
    const dismissal: MaintenanceDismissal = {
      timestamp: getTimestamp(),
      hashcode: getHashCode(),
    };
    storage.set(JSON.stringify(dismissal));
    setOpen(false);
  };

  if (!maintenance.value || !open) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Header>{maintenance.value.subject}</Header>
        <Text>{maintenance.value.body}</Text>
        <Box alignX="center">
          <OkButton text={t('common.ok')} onClick={handleClose} />
        </Box>
      </Content>
    </Container>
  );
};

export default ScheduledMaintenanceMessage;
