/**
 * Returns a promise which resolves after the given amount of milliseconds.
 */
export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * Returns a new function which executes the given function in at least the given
 * amount of milliseconds. If the given functions' execution time is less than the
 * given amount of time, an artificial wait time is added to the returned promise so
 * that in will resolve after the given amount of time.
 *
 * This is useful for preventing flashing, for example when UI components react to
 * some loading state.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function minExecutionTime<T extends (...args: any[]) => any>(
  fn: T,
  ms: number,
): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
  return async (...args) => {
    const before = (performance ?? Date).now();
    let errorOccurred: boolean;
    let result: Awaited<ReturnType<T>>;
    let error: unknown;

    try {
      result = await fn(...args);
      errorOccurred = false;
    } catch (e) {
      error = e;
      errorOccurred = true;
    }

    const after = (performance ?? Date).now();
    const executionTime = after - before;

    if (executionTime < ms) {
      const timeLeft = ms - executionTime;

      await wait(timeLeft);
    }

    if (errorOccurred) {
      throw error;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return result!;
    }
  };
}

/**
 * Returns a new function which executes the given function once there has been no
 * calls for the given amount of milliseconds.
 *
 * This is useful for preventing spamming requests, for example when API requests are
 * made based on user input.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => any>(
  fn: T,
  ms: number,
): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
  let timer: NodeJS.Timeout;

  return (...args) => {
    return new Promise((resolve, reject) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(async () => {
        try {
          resolve(await fn(...args));
        } catch (e) {
          reject(e);
        }
      }, ms);
    });
  };
}

/**
 * Returns a new function which retries the given function with a specified delay
 * and maximum number of retries.
 *
 * This is useful for handling transient errors or network failures.
 *
 * The last encountered error will be re-thrown, if any.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function retryOnError<T extends (...args: any[]) => any>(
  fn: T,
  delay: number,
  maxRetries: number,
): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
  return (...args) => {
    const deferred = new Deferred<Awaited<ReturnType<T>>>();
    let retries = 0;

    const attempt = async () => {
      try {
        deferred.resolve(await fn(...args));
      } catch (e) {
        if (retries < maxRetries) {
          retries++;
          setTimeout(attempt, delay);
        } else {
          deferred.reject(e);
        }
      }
    };

    void attempt();

    return deferred.promise;
  };
}

/**
 * A `Deferred` is a wrapper for a promise which exposes it's resolve and reject
 * functions as methods. This can, in some situations, be more convenient than passing
 * a callback function to a promise constructor on creation. The name is inherited
 * from [AngularJS](https://docs.angularjs.org/api/ng/service/$q#the-deferred-api).
 */
export class Deferred<T> {
  /**
   * The underlying promise.
   */
  public promise: Promise<T>;

  /**
   * Resolves the underlying promise with the given value.
   */
  public resolve: (value: T) => void = () => {
    // This function is replaced on creation
    throw new Error('This should not happen');
  };

  /**
   * Rejects the underlying promise with the given reason/error.
   */
  public reject: (error?: unknown) => void = () => {
    // This function is replaced on creation
    throw new Error('This should not happen');
  };

  public constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
