import * as Yup from 'yup';
import { styled } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Sentry } from '@idk-web/core-utils';
import {
  loseFocus,
  Styling,
  FilledTextField,
  flexGap,
  StandardCard,
  Box,
  useDialog,
  useNotification,
  OkButton,
  TertiaryButton,
  ErrorDialog,
} from '@idk-web/core-ui';
import { updateOwnPortalUser } from '@idk-web/api';
import { translateError } from '@/utils/error';
import { usePortalUser } from '@/hooks/portal/usePortalUser';
import { DEFAULT_NOTIFICATION_AUTO_HIDE_MS } from '@/config';
import routes from '@/components/portal/routes';
import { FormField } from '@/components/common/input/FormField';
import FormFields from '@/components/admin/common/FormFields';
import FieldContainer from '@/components/admin/common/FieldContainer';

const Container = styled(Box).attrs({
  direction: 'vertical',
  alignX: 'center',
})`
  width: 100%;
`;

const Content = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[6],
}))`
  width: 100%;
  padding: ${Styling.spacing(8)};
  @media (min-width: ${Styling.breakpoint('xl')}px) {
    max-width: 1000px;
  }
  @media (max-width: ${Styling.breakpoint('xl')}px) {
    width: 100%;
    padding: ${Styling.spacing(4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    width: 100%;
    padding: ${Styling.spacing(1)};
  }
`;

const Form = styled.form``;

const Card = styled(StandardCard)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: ${Styling.spacing(6)};
  ${({ theme }) => flexGap(theme.spacing[3])};
  @media (max-width: ${Styling.breakpoint('lg')}px) {
    padding: ${Styling.spacing(6, 4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    padding: ${Styling.spacing(4, 2)};
  }
`;

const Title = styled.h2`
  ${Styling.typography('h2')};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const ButtonGroup = styled(Box).attrs(({ theme }) => ({
  spacing: theme.spacing[1.5],
  alignX: 'right',
}))``;

const ProfileScreen: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = usePortalUser();
  const dialog = useDialog();
  const notification = useNotification();
  const formik = useFormik({
    initialValues: {
      email: user.email ?? '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(t('errors.field_is_required', { field: t('common.email') }))
        .email(t('errors.field_must_be_an_email')),
    }),
    async onSubmit({ email }) {
      loseFocus();

      try {
        await updateOwnPortalUser({ email });
        await user.reload();

        notification.show(
          t('portal.profile.save.success'),
          'success',
          DEFAULT_NOTIFICATION_AUTO_HIDE_MS,
        );
        history.push(routes.home);
      } catch (e) {
        Sentry.reportError('Failed to update end user profile', { error: e });
        dialog.show((props) => (
          <ErrorDialog {...props} text={translateError(t, e)} />
        ));
      }
    },
  });

  const handleCancel = () => history.push(routes.home);

  const loading = formik.isSubmitting;

  return (
    <FormikProvider value={formik}>
      <Container>
        <Content>
          <Form onSubmit={formik.handleSubmit}>
            <Card>
              <Title>{t('portal.profile.title')}</Title>
              <FormFields>
                <FieldContainer centered title={t('common.email')}>
                  <FormField
                    name="email"
                    render={(props) => (
                      <FilledTextField
                        {...props}
                        disabled={loading}
                        placeholder={t('common.email')}
                      />
                    )}
                  />
                </FieldContainer>
              </FormFields>
              <ButtonGroup>
                <TertiaryButton
                  type="button"
                  disabled={formik.isSubmitting}
                  text={t('common.cancel')}
                  onClick={handleCancel}
                />
                <OkButton
                  type="submit"
                  disabled={loading || !formik.isValid}
                  text={t('portal.profile.save.button')}
                />
              </ButtonGroup>
            </Card>
          </Form>
        </Content>
      </Container>
    </FormikProvider>
  );
};

export default ProfileScreen;
