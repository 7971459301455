import * as Yup from 'yup';
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { validatePassword, Sentry } from '@idk-web/core-utils';
import {
  loseFocus,
  Styling,
  UnderlinedPasswordField,
  flexGap,
  StandardCard,
  Box,
  useDialog,
  useNotification,
  OkButton,
  ErrorDialog,
} from '@idk-web/core-ui';
import { updateOwnPortalUser } from '@idk-web/api';
import { translateError } from '@/utils/error';
import { usePortalUser } from '@/hooks/portal/usePortalUser';
import { DEFAULT_NOTIFICATION_AUTO_HIDE_MS } from '@/config';
import { FormField } from '@/components/common/input/FormField';

const Container = styled(Box).attrs({
  direction: 'vertical',
  alignX: 'center',
})`
  width: 100%;
`;

const Content = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[5],
}))`
  width: 100%;
  padding: ${Styling.spacing(8)};
  @media (min-width: ${Styling.breakpoint('xl')}px) {
    max-width: 1000px;
  }
  @media (max-width: ${Styling.breakpoint('xl')}px) {
    width: 100%;
    padding: ${Styling.spacing(4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    width: 100%;
    padding: ${Styling.spacing(1)};
  }
`;

const Form = styled.form``;

const Card = styled(StandardCard)`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: ${Styling.spacing(6)};
  ${({ theme }) => flexGap(theme.spacing[3])};
  @media (max-width: ${Styling.breakpoint('lg')}px) {
    padding: ${Styling.spacing(6, 4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    padding: ${Styling.spacing(4, 2)};
  }
`;

const Text = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[1],
}))``;

const Title = styled.h2`
  ${Styling.typography('h2')};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const Description = styled.div`
  ${Styling.typography('body')};
  color: ${({ theme }) => theme.palette.primary.text};
`;

const ChangePassword: FC = () => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const notification = useNotification();
  const user = usePortalUser();
  const formik = useFormik({
    initialValues: {
      password: '',
      password2: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(
          t('errors.field_is_required', { field: t('common.password') }),
        )
        .test(
          'Password',
          t('errors.field_must_be_a_valid_password', {
            field: t('common.password'),
          }),
          (password) => validatePassword(password ?? ''),
        ),
      password2: Yup.string()
        .required(
          t('errors.field_is_required', { field: t('common.password') }),
        )
        .oneOf([Yup.ref('password')], t('errors.passwords_must_match')),
    }),
    async onSubmit({ password }) {
      loseFocus();

      try {
        await updateOwnPortalUser({ password });

        await user.reload();

        notification.show(
          t('portal.change_password.success'),
          'success',
          DEFAULT_NOTIFICATION_AUTO_HIDE_MS,
        );
      } catch (e) {
        Sentry.reportError('Failed to update portal user password', {
          error: e,
        });
        dialog.show((props) => (
          <ErrorDialog {...props} text={translateError(t, e)} />
        ));
      }
    },
  });

  const loading = formik.isSubmitting;

  return (
    <FormikProvider value={formik}>
      <Container>
        <Content>
          <Form onSubmit={formik.handleSubmit}>
            <Card>
              <Text>
                <Title>{t('portal.change_password.title')}</Title>
                <Description>{t('portal.change_password.text')}</Description>
              </Text>
              <FormField
                name="password"
                render={(props) => (
                  <UnderlinedPasswordField
                    {...props}
                    autoComplete="new-password"
                    placeholder={t('common.password')}
                  />
                )}
              />
              <FormField
                name="password2"
                render={(props) => (
                  <UnderlinedPasswordField
                    {...props}
                    autoComplete="new-password"
                    placeholder={t('common.repeat_password')}
                  />
                )}
              />
              <Box alignX="right">
                <OkButton
                  type="submit"
                  disabled={loading || !formik.isValid}
                  text={t('portal.change_password.submit')}
                />
              </Box>
            </Card>
          </Form>
        </Content>
      </Container>
    </FormikProvider>
  );
};

export default ChangePassword;
