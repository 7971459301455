import { DependencyList, useEffect } from 'react';

const INTERVAL = 20_000;
const EPSILON = 5_000;

/**
 * This is a fun/hacky one.
 *
 * This is a hook that runs a periodic check to see whether the difference in time
 * between each check matches the expected period interval. If the difference is
 * greater than the interval time plus some error margin, then it suggests that
 * the tab (and perhaps computer) was suspended for some time. The given function
 * is invoked on the first render after a suspension is detected.
 */
export function useSuspended(
  func: (dt: number) => void,
  deps: DependencyList,
): void {
  useEffect(() => {
    let lastRun = (performance ?? Date).now();

    const id = setInterval(() => {
      const now = (performance ?? Date).now();
      const dt = now - lastRun;

      if (dt > INTERVAL + EPSILON) {
        func(dt);
      }

      lastRun = now;
    }, INTERVAL);

    return () => clearInterval(id);
  }, deps);
}
