/**
 * Returns the value type of an object
 */
export type ValueOf<T> = T[keyof T];

/**
 * A type predicate which checks whether the given object contains a property with the given name.
 *
 * The value, if any, will be narrowed down to a type of `unknown`.
 */
export function hasProperty<O extends object, N extends string>(
  obj: O,
  name: N,
): obj is O & { [_ in N]: unknown } {
  return name in obj;
}

/**
 * A type predicate which checks that the given value is not `null` or `undefined`.
 *
 * Especially useful as a function reference for `Array#filter`.
 */
export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}
