import { styled } from 'styled-components';
import React, { FC, PropsWithChildren } from 'react';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  position: absolute;
  padding: 8px 12px;
  color: ${({ theme }) => theme.palette.grayscale.main};
  background-color: ${({ theme }) => theme.palette.grayscale.white};
`;

const HorizontalHr = styled.hr`
  background: ${({ theme }) => theme.palette.grayscale.light4};
  height: 1px;
  width: 100%;
  margin: 0;
  border: 0;
`;

const VerticalHr = styled.hr`
  background: ${({ theme }) => theme.palette.grayscale.light4};
  width: 1px;
  margin: 0;
  border: 0;
`;

export type DividerProps = PropsWithChildren<{
  className?: string;
  orientation: 'horizontal' | 'vertical';
}>;

export const Divider: FC<DividerProps> = ({
  className,
  orientation,
  children,
}) => {
  const element =
    orientation === 'horizontal' ? (
      <HorizontalHr className={className} />
    ) : (
      <VerticalHr className={className} />
    );

  if (children) {
    return (
      <Container>
        {element}
        {children && <Text>{children}</Text>}
      </Container>
    );
  }

  return element;
};
