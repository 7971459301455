import { DefaultRootState, LocaleState } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import app from '@/utils/app';

const initialState: LocaleState = {
  language: app.locale,
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState: initialState as LocaleState,
  reducers: {
    changeLanguage(state, action: PayloadAction<string>): LocaleState {
      return {
        language: action.payload,
      };
    },
  },
});

export const { changeLanguage } = localeSlice.actions;

export const selectLanguage = (state: DefaultRootState) =>
  state.locale.language;

export default localeSlice.reducer;
