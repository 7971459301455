import { styled } from 'styled-components';
import React, { useEffect, useState, FC, PropsWithChildren } from 'react';
import { Styling } from '@/styling';
import { Overlay, OverlayProps } from '@/components/visual/Overlay';

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: ${Styling.zIndex('backdrop')};
`;

export type BackdropProps = PropsWithChildren<OverlayProps>;

export const Backdrop: FC<BackdropProps> = ({
  color = 'rgba(0, 0, 0, 0.5)',
  durationMs = 100,
  children,
  show,
  onFullyGone,
  ...props
}) => {
  const [isClosed, setIsClosed] = useState(!show);

  useEffect(() => {
    if (show) {
      setIsClosed(false);
    }
  }, [show]);

  const handleGone = () => {
    setIsClosed(true);
    onFullyGone?.();
  };

  if (isClosed) {
    return null;
  }

  return (
    <Container>
      <Overlay
        show={show}
        color={color}
        durationMs={durationMs}
        onFullyGone={handleGone}
        {...props}
      />
      {children}
    </Container>
  );
};
