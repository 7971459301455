import { styled, CSSObject } from 'styled-components';
import { isFlexGapSupported } from '@/flexGap';

export type BoxProps = {
  direction?: 'horizontal' | 'vertical';
  alignX?: 'left' | 'center' | 'right' | 'space-around' | 'space-between';
  alignY?: 'top' | 'center' | 'bottom' | 'space-around' | 'space-between';
  canWrap?: boolean;
  spacing?: string | number;
};

/**
 * An axis aligned flex-like box. It is aligned by axis instead of direction, like flexbox.
 *
 * It may or may not use flexbox internally depending on whether the browser supports it.
 */
export const Box = styled.div<BoxProps>(
  ({ direction = 'horizontal', alignX, alignY, canWrap, spacing }) => {
    const styling: CSSObject = {
      width: '100%',
      display: 'flex',
      flexDirection: direction === 'horizontal' ? 'row' : 'column',
    };

    const justifyContent = (align?: string): string => {
      switch (align) {
        case 'center':
        case 'space-around':
        case 'space-between':
          return align;
        case 'top':
        case 'left':
          return 'start';
        case 'right':
        case 'bottom':
          return 'end';
        default:
          return 'stretch';
      }
    };

    const alignContent = (align?: string): string => {
      switch (align) {
        case 'center':
        case 'space-around':
        case 'space-between':
          return align;
        case 'top':
        case 'left':
          return 'start';
        case 'right':
        case 'bottom':
          return 'end';
        default:
          return 'stretch';
      }
    };

    const alignItems = (align?: string): string => {
      switch (align) {
        case 'center':
          return align;
        case 'top':
        case 'left':
          return 'start';
        case 'right':
        case 'bottom':
          return 'end';
        default:
          return 'stretch';
      }
    };

    if (canWrap === true) {
      styling.flexWrap = 'wrap';
    } else if (canWrap === false) {
      styling.flexWrap = 'nowrap';
    }

    if (direction === 'vertical') {
      styling.justifyContent = justifyContent(alignY);

      if (canWrap) {
        styling.alignContent = alignContent(alignX);
      } else {
        styling.alignItems = alignItems(alignX);
      }
    } else {
      styling.justifyContent = justifyContent(alignX);

      if (canWrap) {
        styling.alignContent = alignContent(alignY);
      } else {
        styling.alignItems = alignItems(alignY);
      }
    }

    if (spacing) {
      if (isFlexGapSupported()) {
        styling.gap = spacing;
      } else {
        if (direction === 'horizontal') {
          styling['& > * + *'] = {
            marginLeft: spacing,
          };
        } else {
          styling['& > * + *'] = {
            marginTop: spacing,
          };
        }
      }
    }

    return styling;
  },
);
