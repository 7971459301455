import { styled, DefaultTheme } from 'styled-components';
import { PropsWithChildren } from 'react';
import { Styling } from '@/styling';

export type TypographyProps = PropsWithChildren<{
  type: keyof DefaultTheme['typography'];
}>;

export const Typography = styled.div<TypographyProps>`
  ${(props) => Styling.typography(props.type)(props)};
`;
