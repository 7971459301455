import { styled } from 'styled-components';
import { createPortal } from 'react-dom';
import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { clamp } from '@idk-web/core-utils';
import { Styling } from '@/styling';
import { useWindowSize } from '@/hooks/useWindowSize';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const tooltipRoot = document.getElementById('tooltip-root')!;

const Container = styled.div`
  position: absolute;
  z-index: ${Styling.zIndex('tooltip')};
  box-shadow: ${Styling.shadow('tooltip')};
`;

export enum TooltipAlignment {
  LEFT,
  TOP,
  RIGHT,
  BOTTOM,
}

export type TooltipProps = PropsWithChildren<{
  className?: string;
  content: ReactNode;
  align?: TooltipAlignment;
  spacing?: number;
}>;

export const Tooltip: FC<TooltipProps> = ({
  className,
  content,
  align = TooltipAlignment.TOP,
  spacing = 8,
  children,
}) => {
  const windowSize = useWindowSize();
  const [show, setShow] = useState(false);
  const [style, setStyle] = useState<CSSProperties | undefined>(undefined);
  const [containerRef, setContainerRef] = useState<HTMLSpanElement | null>();
  const [tooltipRef, setTooltipRef] = useState<HTMLSpanElement | null>();
  const updateCoords = useCallback(() => {
    if (!containerRef || !tooltipRef) {
      return;
    }

    const containerRect = containerRef.getBoundingClientRect();
    const tooltipRect = tooltipRef.getBoundingClientRect();
    const minX = window.scrollX + spacing;
    const maxX = window.innerWidth + window.scrollX - spacing;
    const minY = window.scrollY + spacing;
    const maxY = window.innerHeight + window.scrollY - spacing;

    switch (align) {
      case TooltipAlignment.LEFT:
        setStyle({
          left: clamp(
            containerRect.x - spacing + window.scrollX,
            minX + tooltipRect.width,
            maxX,
          ),
          top: clamp(
            containerRect.y + containerRect.height / 2 + window.scrollY,
            minY + tooltipRect.height / 2,
            maxY - tooltipRect.height / 2,
          ),
          transform: 'translate(-100%, -50%)',
        });
        break;
      case TooltipAlignment.TOP:
        setStyle({
          left: clamp(
            containerRect.x + containerRect.width / 2 + window.scrollX,
            minX + tooltipRect.width / 2,
            maxX - tooltipRect.width / 2,
          ),
          top: clamp(
            containerRect.y - spacing + window.scrollY,
            minY + tooltipRect.height,
            maxY,
          ),
          transform: 'translate(-50%, -100%)',
        });
        break;
      case TooltipAlignment.RIGHT:
        setStyle({
          left: clamp(
            containerRect.x + containerRect.width + spacing + window.scrollX,
            minX,
            maxX - tooltipRect.width,
          ),
          top: clamp(
            containerRect.y + containerRect.height / 2 + window.scrollY,
            minY + tooltipRect.height / 2,
            maxY - tooltipRect.height / 2,
          ),
          transform: 'translate(0%, -50%)',
        });
        break;
      case TooltipAlignment.BOTTOM:
        setStyle({
          left: clamp(
            containerRect.x + containerRect.width / 2 + window.scrollX,
            minX + tooltipRect.width / 2,
            maxX - tooltipRect.width / 2,
          ),
          top: clamp(
            containerRect.y + containerRect.height + spacing + window.scrollY,
            minY,
            maxY - tooltipRect.height,
          ),
          transform: 'translate(-50%, 0%)',
        });
        break;
    }
  }, [windowSize, containerRef, tooltipRef, align, spacing]);

  useEffect(() => updateCoords(), [updateCoords, show]);

  useEffect(() => {
    if (containerRef) {
      // Native event listeners are used instead of React events as a workaround for a bug in
      // React where the mouseleave event is not fired when the element have a disabled child.
      // For more info, see https://github.com/facebook/react/issues/10396
      const show = () => setShow(true);
      const hide = () => setShow(false);

      containerRef.addEventListener('mouseenter', show);
      containerRef.addEventListener('mouseleave', hide);

      return () => {
        containerRef.removeEventListener('mouseenter', show);
        containerRef.removeEventListener('mouseleave', hide);
      };
    }
  }, [containerRef]);

  return (
    <>
      <span ref={(el) => setContainerRef(el)}>{children}</span>
      {show &&
        createPortal(
          <Container
            ref={(el) => setTooltipRef(el)}
            className={className}
            style={style}
          >
            {content}
          </Container>,
          tooltipRoot,
        )}
    </>
  );
};
