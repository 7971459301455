import { useCallback, useEffect, useMemo, useState } from 'react';
import { LocalStorage as Storage } from '@/storage';

type LocalStorage = {
  /**
   * The value of the LocalStorage item of the given key, if any.
   */
  value: string | null;
  /**
   * Sets the value of the given key to the given value.
   */
  set(value: string): void;
  /**
   * Removes the LocalStorage item of the given key.
   */
  clear(): void;
};

/**
 * A hook used for reading and updating a single LocalStorage item.
 *
 * This hook will react to changes in LocalStorage.
 */
export function useLocalStorage(key: string): LocalStorage {
  const [value, setValue] = useState<string | null>(null);
  const set = useCallback(
    (value: string) => Storage.setItem(key, value),
    [key],
  );
  const clear = useCallback(() => Storage.removeItem(key), [key]);
  const onChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === key) {
        setValue(event.newValue);
      }
    },
    [key],
  );

  useEffect(() => {
    setValue(Storage.getItem(key) ?? null);
  }, []);

  useEffect(() => {
    window.addEventListener('storage', onChange);

    return () => window.removeEventListener('storage', onChange);
  }, [onChange]);

  return useMemo(() => ({ value, set, clear }), [value, set, clear]);
}
