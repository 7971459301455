type Unit = keyof Duration;

export class Duration {
  private constructor(
    public weeks: number,
    public days: number,
    public hours: number,
    public minutes: number,
    public seconds: number,
  ) {}

  /**
   * Returns the given amount of seconds as a `Duration`
   */
  public static fromSeconds<T extends Unit[]>(
    seconds: number,
    units: T,
  ): Pick<Duration, T[number]> {
    return Duration.betweenTimestamps(0, seconds, units);
  }

  /**
   * Returns the duration between the given UNIX timestamps
   */
  public static betweenTimestamps<T extends Unit[]>(
    a: number,
    b: number,
    units: T,
  ): Pick<Duration, T[number]> {
    const from = Math.min(a, b);
    const to = Math.max(a, b);
    let diff = to - from;

    let weeks = 0;
    if (units.includes('weeks')) {
      weeks = Math.floor(diff / 604800);
      diff -= weeks * 604800;
    }

    let days = 0;
    if (units.includes('days')) {
      days = Math.floor(diff / 86400);
      diff -= days * 86400;
    }

    let hours = 0;
    if (units.includes('hours')) {
      hours = Math.floor(diff / 3600) % 24;
      diff -= hours * 3600;
    }

    let minutes = 0;
    if (units.includes('minutes')) {
      minutes = Math.floor(diff / 60) % 60;
      diff -= minutes * 60;
    }

    let seconds = 0;
    if (units.includes('seconds')) {
      seconds = diff % 60;
    }

    return new Duration(weeks, days, hours, minutes, seconds);
  }
}
