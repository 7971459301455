import { StyleSheetManager } from 'styled-components';
import { Router } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import React from 'react';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { NotificationProvider, DialogProvider } from '@idk-web/core-ui';
import { ThemeProvider } from '@/styled-components/theme';
import SentryProvider from '@/sentry/provider';
import * as Sentry from '@/sentry';
import store from '@/redux/store';
import * as I18n from '@/i18n';
import history from '@/history';
import App from '@/App';
import '@/client';

dayjs.extend(CustomParseFormat);

Sentry.initialize();
I18n.initialize();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const appRoot = document.getElementById('app-root')!;

createRoot(appRoot).render(
  <React.StrictMode>
    <HelmetProvider>
      <StoreProvider store={store}>
        <SentryProvider>
          <StyleSheetManager>
            <ThemeProvider>
              <DialogProvider>
                <NotificationProvider>
                  <Router history={history}>
                    <App />
                  </Router>
                </NotificationProvider>
              </DialogProvider>
            </ThemeProvider>
          </StyleSheetManager>
        </SentryProvider>
      </StoreProvider>
    </HelmetProvider>
  </React.StrictMode>,
);
