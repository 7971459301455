import React, { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';

export const SENSITIVE_CLASS_NAME = '__sensitive';

export type SensitiveProps = PropsWithChildren<{}>;

/**
 * Marks the content as sensitive which can be used as a hint to omit it from showing up in Sentry's session
 * replay functionality.
 */
export const Sensitive: FC<SensitiveProps> = ({ children }) => {
  const addSentryIgnoreClass = (child: ReactNode) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement<{ className: string }>, {
        className: `${child.props.className} ${SENSITIVE_CLASS_NAME}`,
      });
    }

    return <span className={SENSITIVE_CLASS_NAME}>{child}</span>;
  };

  return <>{React.Children.map(children, addSentryIgnoreClass)}</>;
};
