import { styled, css, keyframes, useTheme } from 'styled-components';
import { TailSpin } from 'react-loader-spinner';
import React, {
  ButtonHTMLAttributes,
  ComponentType,
  DetailedHTMLProps,
  FC,
  ReactNode,
} from 'react';
import { Styling } from '@/styling';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 0;
  top: -12px;
  animation: ${fadeIn} 200ms linear 1;
`;

const Loader: FC = () => {
  const theme = useTheme();

  return (
    <LoaderWrapper>
      <TailSpin
        width={`24px`}
        height={`24px`}
        color={theme.palette.primary.main}
        ariaLabel="loading-indicator"
      />
    </LoaderWrapper>
  );
};

const IconButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: ${Styling.radii('button')};
  cursor: pointer;
  transition:
    color 80ms,
    background-color 80ms;
  height: 36px;
  width: 36px;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

export type IconButtonProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: string | ComponentType<any>;
  icon: ReactNode;
  loading?: boolean;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const IconButton: FC<IconButtonProps> = ({
  className = '',
  loading,
  disabled,
  icon,
  ...props
}) => {
  if (loading) {
    className += ' loading';
  }

  return (
    <IconButtonBase
      className={className}
      disabled={loading || disabled}
      {...props}
    >
      {loading ? <Loader /> : icon}
    </IconButtonBase>
  );
};

const disabledButtonStyle = css(({ theme }) => ({
  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: theme.palette.disabled.main,
    color: theme.palette.secondary.light3,
    '&:hover': {
      backgroundColor: theme.palette.disabled.main,
    },
  },
}));

export const PrimaryActionIconButton = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.primary.light1,
    backgroundColor: '#fff3e5', // TODO move to theme
    '&:hover': {
      backgroundColor: '#FFEAC8', // TODO move to theme
    },
    '&:active': {
      backgroundColor: theme.palette.disabled.main,
      color: '#A5A5A5', // TODO move to theme
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  }),
  disabledButtonStyle,
);

export const AlternativeActionIconButton = styled(IconButton)(
  ({ theme }) => ({
    color: '#3d97e5', // TODO move to theme
    backgroundColor: '#e5f2ff', // TODO move to theme
    '&:hover': {
      backgroundColor: '#c8e6ff', // TODO move to theme
    },
    '&:active': {
      backgroundColor: theme.palette.disabled.main,
      color: '#A5A5A5', // TODO move to theme
    },
    '&:focus': {
      outline: '2px solid #6ab1f3', // TODO move to theme
    },
  }),
  disabledButtonStyle,
);

export const DangerActionIconButton = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.error.main,
    backgroundColor: '#F1E9E9', // TODO move to theme
    '&:hover': {
      backgroundColor: '#f6d7d7', // TODO move to theme
    },
    '&:active': {
      backgroundColor: theme.palette.disabled.main,
      color: '#A5A5A5', // TODO move to theme
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.error.light1}`,
    },
  }),
  disabledButtonStyle,
);

export const CircleIconButton = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.grayscale.main,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grayscale.light5,
    },
  }),
  disabledButtonStyle,
);

export const AlternativeCircleIconButton = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'white',
    },
  }),
  disabledButtonStyle,
);
