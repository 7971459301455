import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { hostname, Styling, ResponsiveImage, Box } from '@idk-web/core-ui';
import { SITE_LINK, TRADEMARK } from '@/config';
import idkollen from '@/assets/svg/idkollen_neg.svg';
import identisure from '@/assets/svg/identisure_neg.svg';

const CURRENT_YEAR = new Date().getFullYear();

const Container = styled(Box).attrs(({ theme }) => ({
  as: 'footer',
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[1],
}))`
  ${Styling.typography('body')}
  background-color: ${({ theme }) => theme.palette.grayscale.dark3};
  color: ${({ theme }) => theme.palette.secondary.text};
  padding: ${Styling.spacing(3)} 0;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.palette.secondary.text};
`;

const Logo = styled(ResponsiveImage).attrs({
  width: 110,
  height: 24,
  alt: 'Logo', // TODO translate
})`
  height: 24px;
`;

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Logo src={hostname.tld === 'se' ? idkollen : identisure} />
      <div>
        {t('footer.service_is_provided_by')}
        &nbsp;
        <Link href={SITE_LINK} target="_blank">
          {TRADEMARK}
        </Link>
        . © {CURRENT_YEAR}
      </div>
    </Container>
  );
};

export default Footer;
