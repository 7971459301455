import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import React, { FC, Suspense, useEffect } from 'react';
import { hostname, useDialog } from '@idk-web/core-ui';
import app from '@/utils/app';
import { selectLanguage } from '@/redux/locale.slice';
import useNewVersionAvailable from '@/hooks/useNewVersionAvailable';
import PortalApp from '@/components/portal/PortalApp';
import NewVersionDialog from '@/components/common/dialog/NewVersionDialog';

const App: FC = () => {
  const { i18n } = useTranslation();
  const language = useSelector(selectLanguage);
  const dialog = useDialog();

  useEffect(() => {
    void i18n.changeLanguage(language);
  }, [language]);

  useNewVersionAvailable(() => {
    dialog.show(NewVersionDialog);
  }, []);

  return (
    <>
      <Helmet>
        <title>{hostname.tld === 'se' ? 'IDkollen' : 'Identisure'}</title>
      </Helmet>
      {app.env === 'prod' && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-2XPWH7F7HH"
          ></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){ dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', 'G-2XPWH7F7HH');
            `}
          </script>
        </Helmet>
      )}
      <Suspense fallback={null}>
        <PortalApp />
      </Suspense>
    </>
  );
};

export default App;
