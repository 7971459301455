import { styled, keyframes } from 'styled-components';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { Styling } from '@/styling';
import { Box } from '@/components/layout/Box';

const POPIN_DURATION = 20;
const FADEOUT_DURATION = 150;

const popIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`;

type ContainerProps = {
  show: boolean;
};

const Container = styled(Box).attrs(({ theme }) => ({
  spacing: theme.spacing[2],
  alignX: 'space-between',
  alignY: 'center',
}))<ContainerProps>`
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.text};
  background-color: ${({ theme }) => theme.palette.grayscale.white};
  box-shadow: ${Styling.shadow('notification')};
  padding: ${Styling.spacing(2, 2, 2, 3)};
  border-radius: ${Styling.radii('notification')};
  z-index: ${Styling.zIndex('notification')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  animation: ${popIn} ${POPIN_DURATION}ms linear 1;
  transition: opacity ${FADEOUT_DURATION}ms;

  &.info {
    border-left: 8px solid #3f78e3;
  }

  &.success {
    border-left: 8px solid #7be33f;
  }

  &.warn {
    border-left: 8px solid #fdbd3e;
  }

  &.error {
    border-left: 8px solid #fd3f3f;
  }
`;

const CloseButton = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  transition:
    color 80ms,
    background-color 80ms;
  height: 26px;
  width: 26px;
  color: #a5a5a5; // TODO move to theme
  background-color: ${({ theme }) => theme.palette.disabled.main};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.grayscale.white};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.disabled.main};
    color: #a5a5a5; // TODO move to theme
  }
`;

export type NotificationType = 'info' | 'success' | 'warn' | 'error';

export type NotificationProps = PropsWithChildren<{
  className?: string;
  open: boolean;
  type: NotificationType;
  closeable?: boolean;
  onClose?(): void;
  autoHideDurationMs?: number;
  durationMs?: number;
}>;

export const Notification: FC<NotificationProps> = ({
  className,
  open,
  type,
  autoHideDurationMs,
  closeable = true,
  onClose,
  children,
}) => {
  const [isClosing, setIsClosing] = useState(!open);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => setIsClosing(!open), [open]);

  useEffect(() => {
    if (isHovering) {
      return;
    } else if (!autoHideDurationMs && closeable) {
      return;
    }

    const handle = setTimeout(handleClose, autoHideDurationMs ?? 5000);

    return () => clearTimeout(handle);
  }, [autoHideDurationMs, isHovering]);

  useEffect(() => {
    if (isHovering || !isClosing) {
      return;
    }

    const handle = setTimeout(() => onClose?.(), FADEOUT_DURATION);

    return () => clearTimeout(handle);
  }, [isClosing, isHovering]);

  const handleClose = () => setIsClosing(true);

  return (
    <Container
      show={!isClosing}
      className={`${className} ${type}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {children}
      <CloseButton onClick={handleClose}>
        <FontAwesomeIcon icon={faXmark} size="lg" />
      </CloseButton>
    </Container>
  );
};
