import {
  DependencyList,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useIsMounted } from '@/hooks/useIsMounted';

type AsyncData<T, E> =
  | {
      state: AsyncState.SUCCESS;
      value: T;
      error: null;
    }
  | {
      state: AsyncState.ERROR;
      value: null;
      error: E;
    }
  | {
      state: AsyncState.PENDING;
      value: T | null;
      error: E | null;
    };

export enum AsyncState {
  SUCCESS,
  ERROR,
  PENDING,
}

export type Async<T, E> = {
  /**
   * Re-executes the function.
   * @param clean
   *   Determines whether to reset the value and error properties to `null` during reload. Defaults to false.
   */
  reload(clean?: boolean): Promise<void>;
} & AsyncData<T, E>;

/**
 * A hook that executes the given async function. The function is executed on
 * first render and subsequently re-executed every time the given dependency array
 * changes or on every explicit call to `Async::reload()`.
 */
export function useAsync<T, E = unknown>(
  func: (signal?: AbortSignal) => Promise<T>,
  deps: DependencyList,
): Async<T, E> {
  const async = useAsyncLazy<T, E>(func, deps);

  useEffect(() => {
    // Load data immediately
    void async.reload();
  }, []);

  return async;
}

/**
 * A hook that lazily executes the given async function. The function is executed
 * either every time the given dependency array changes or on every explicit call
 * to `Async::reload()`, but NOT on first render.
 */
export function useAsyncLazy<T, E = unknown>(
  func: (signal?: AbortSignal) => Promise<T>,
  deps: DependencyList,
): Async<T, E> {
  const isMounted = useIsMounted();
  const isFirstRender = useRef(true);
  const [data, setData] = useState<AsyncData<T, E>>({
    state: AsyncState.PENDING,
    value: null,
    error: null,
  });
  const reload = useCallback(async (clean = false, signal?: AbortSignal) => {
    if (clean) {
      setData({
        state: AsyncState.PENDING,
        value: null,
        error: null,
      });
    } else {
      setData((data) => ({
        ...data,
        state: AsyncState.PENDING,
      }));
    }

    try {
      const result = await func(signal);

      if (isMounted() && !signal?.aborted) {
        setData({
          state: AsyncState.SUCCESS,
          value: result,
          error: null,
        });
      }
    } catch (e: unknown) {
      if (isMounted() && !signal?.aborted) {
        setData({
          state: AsyncState.ERROR,
          value: null,
          error: e as E,
        });
      }
    }
  }, deps);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      // Skip reload on first render
      return;
    }

    const controller = new AbortController();

    void reload(false, controller.signal);

    return () => controller.abort();
  }, [reload]);

  return useMemo(() => ({ ...data, reload }), [data, reload]);
}
