import { styled } from 'styled-components';
import { createPortal } from 'react-dom';
import React, { FC, PropsWithChildren } from 'react';
import { Backdrop } from '@/components/visual/Backdrop';

const Menu = styled.nav<{ open: boolean; anchor: AnchorPoint }>(
  ({ open, anchor, theme }) => ({
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: '100%',
    top: 0,
    left: anchor === 'left' ? 0 : undefined,
    right: anchor === 'right' ? 0 : undefined,
    backgroundColor: theme.palette.grayscale.light5,
    color: theme.palette.primary.text,
    boxShadow: open
      ? 'rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px'
      : 'none',
    transform: (() => {
      if (open) {
        return 'translateX(0)';
      }

      return anchor === 'left' ? 'translateX(-100%)' : 'translateX(100%)';
    })(),
    transition: 'transform 200ms, box-shadow 200ms',
  }),
);

export type AnchorPoint = 'left' | 'right';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const drawerRoot = document.getElementById('drawer-root')!;

export type DrawerProps = PropsWithChildren<{
  anchor?: AnchorPoint;
  open: boolean;
  onClose?(): void;
  className?: string;
}>;

export const Drawer: FC<DrawerProps> = ({
  anchor = 'left',
  open,
  onClose,
  className,
  children,
}) => {
  return createPortal(
    <>
      <Backdrop show={open} onClick={onClose} />
      <Menu className={className} open={open} anchor={anchor}>
        {children}
      </Menu>
    </>,
    drawerRoot,
  );
};
