const userAgent = navigator.userAgent;

export enum Platform {
  IOS,
  ANDROID,
  WINDOWS, // Desktop or tablet
  MACOS,
  LINUX,
  OTHER,
}

export let platform: Platform;

if (/Android/.test(userAgent)) {
  platform = Platform.ANDROID;
} else if (/iPhone|iPad|iPod/.test(userAgent)) {
  platform = Platform.IOS;
} else if (/Windows NT/.test(userAgent)) {
  platform = Platform.WINDOWS;
} else if (/Macintosh/.test(userAgent)) {
  platform = Platform.MACOS;
} else if (/Linux/.test(userAgent)) {
  platform = Platform.LINUX;
} else {
  platform = Platform.OTHER;
}
