import { useCallback, useEffect, useRef } from 'react';

// This, and all its' uses, can most likely be removed after upgrading to React 18.
// See https://github.com/facebook/react/pull/22114.

export type IsMounted = () => boolean;

/**
 * A hook returning a function used to check whether the parent component is still mounted or not.
 */
export function useIsMounted(): IsMounted {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback(() => mounted.current, []);
}
