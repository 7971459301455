import { AxiosResponse } from 'axios';
import { AuthType, Country, ServiceType } from '@/types';
import { Page } from '@/common';
import { client } from '@/client';

export type PortalUser = {
  id: number;
  username: string;
  isAdmin: boolean;
  displayName: string | null;
  authType: AuthType;
  logAccess: boolean;
  email: string | null;
  country: Country | null;
  mustChangePassword: boolean;
  hasValidatedEmail: boolean;
  countries: {
    country: Country;
    services: ServiceType[];
  }[];
};

export type UpdateOwnUserRequest = {
  username: string;
  password: string;
  displayName: string;
  email: string;
};

export type UpdateUserRequest = {
  ssn?: string;
  country?: Country;
  username?: string;
  password?: string;
  displayName?: string;
  email?: string;
  isAdmin: boolean;
  services: {
    name: ServiceType;
    countries: Country[];
  }[];
};

export async function getPortalUserById(
  userId: number,
  signal?: AbortSignal,
): Promise<PortalUser> {
  return client
    .get<PortalUser>(`/company-user/${userId}`, { signal })
    .then((response) => response.data);
}

export async function getOwnPortalUser(
  signal?: AbortSignal,
): Promise<PortalUser> {
  return client
    .get<PortalUser>('/company-user/me', { signal })
    .then((response) => response.data);
}

export async function getPortalUsers(
  params?: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?:
      | 'id'
      | 'email'
      | 'username'
      | 'displayName'
      | 'createdAt'
      | 'lastLoggedInAt';
    sortDirection?: 'ascending' | 'descending';
    isAdmin?: boolean;
    authType?: AuthType;
    username?: string;
    country?: Country;
    q?: string;
  },
  signal?: AbortSignal,
): Promise<Page<PortalUser>> {
  return client
    .get('/company-user', { params, signal })
    .then((response) => response.data);
}

export async function addPortalUser(
  request: UpdateUserRequest,
  signal?: AbortSignal,
): Promise<PortalUser> {
  return client
    .post<
      UpdateUserRequest,
      AxiosResponse<PortalUser>
    >('/company-user', request, { signal })
    .then((response) => response.data);
}

export async function updatePortalUser(
  userId: number,
  request: Partial<UpdateUserRequest>,
  signal?: AbortSignal,
): Promise<PortalUser> {
  return client
    .patch<
      UpdateUserRequest,
      AxiosResponse<PortalUser>
    >(`/company-user/${userId}`, request, { signal })
    .then((response) => response.data);
}

export async function updateOwnPortalUser(
  request: Partial<UpdateOwnUserRequest>,
  signal?: AbortSignal,
): Promise<PortalUser> {
  return client
    .patch<
      UpdateUserRequest,
      AxiosResponse<PortalUser>
    >('/company-user/me', request, { signal })
    .then((response) => response.data);
}

export async function deletePortalUser(
  userId: number,
  signal?: AbortSignal,
): Promise<void> {
  return client
    .delete(`/company-user/${userId}`, { signal })
    .then((response) => response.data);
}

export async function updatePortalUserRole(
  userId: number,
  request: Partial<UpdateUserRequest>,
  signal?: AbortSignal,
): Promise<PortalUser> {
  return client
    .patch<
      never,
      AxiosResponse<PortalUser>
    >(`/company-user/${userId}`, request, { signal })
    .then((response) => response.data);
}

export async function validatePortalUserEmail(
  code: string,
  signal?: AbortSignal,
): Promise<void> {
  return client.post('/company-user/email/validate', null, {
    params: { code },
    signal,
  });
}

export async function resendPortalUserValidationEmail(
  userId: number,
  signal?: AbortSignal,
): Promise<void> {
  return client.post(`/company-user/${userId}/email/validate`, null, {
    signal,
  });
}

export async function resendOwnPortalUserValidationEmail(
  signal?: AbortSignal,
): Promise<void> {
  return client.post(`/company-user/me/email/validate`, null, { signal });
}
