import { styled } from 'styled-components';
import React, {
  DetailedHTMLProps,
  FC,
  ImgHTMLAttributes,
  useState,
} from 'react';

type ImgProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const BaseImage = styled.img`
  height: 100%;
  width: auto;
  vertical-align: middle;
  object-fit: contain;
`;

export type ResponsiveImageProps = ImgProps & {
  className?: string;
};

/**
 * A responsive image
 *
 * It's given a `.loaded` class when it's loaded.
 */
export const ResponsiveImage: FC<ResponsiveImageProps> = ({
  className,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <BaseImage
      loading="lazy"
      {...(props as object)}
      onLoad={() => setLoaded(true)}
      className={`${className} ${loaded ? 'loaded' : ''}`}
    />
  );
};
