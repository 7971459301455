import { styled, keyframes } from 'styled-components';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Styling } from '@/styling';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div<OverlayProps>`
  position: absolute;
  z-index: ${Styling.zIndex('backdrop')};
  background-color: ${({ color }) => color};
  width: 100%;
  height: 100%;
  opacity: ${({ show }) => (show ? 1 : 0)};
  animation: ${fadeIn} ${({ durationMs }) => durationMs}ms linear 1;
  transition: opacity ${({ durationMs }) => durationMs}ms;
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
`;

export type OverlayProps = PropsWithChildren<{
  className?: string;
  show: boolean;
  color?: string;
  durationMs?: number;
  onClick?(): void;
  onFullyVisible?(): void;
  onFullyGone?(): void;
}>;

export const Overlay: FC<OverlayProps> = ({
  className,
  show,
  color = 'rgba(255, 255, 255, 0.5)',
  durationMs = 100,
  onClick,
  onFullyVisible,
  onFullyGone,
  children,
}) => {
  useEffect(() => {
    let handle: NodeJS.Timeout;

    if (show) {
      handle = setTimeout(() => onFullyVisible?.(), durationMs);
    } else {
      handle = setTimeout(() => onFullyGone?.(), durationMs);
    }

    return () => clearTimeout(handle);
  }, [show]);

  return (
    <Container
      className={className}
      show={show}
      color={color}
      durationMs={durationMs}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};
