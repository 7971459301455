import {
  useDispatch,
  useSelector,
  Company as ApiCompany,
  DefaultRootState,
} from 'react-redux';
import React, { useCallback, useMemo } from 'react';
import { Axios, Sentry } from '@idk-web/core-utils';
import { useDialog } from '@idk-web/core-ui';
import { getCompanyConfig, changeCompany } from '@idk-web/api';
import { authenticate, selectPortalCompany } from '@/redux/portalAuth.slice';
import MultiUserMaxUserDialog from '@/components/portal/login/MultiUserMaxUserDialog';

export type Company = ApiCompany & {
  /**
   * Reloads the current company configuration globally
   */
  reload(): Promise<void>;
  /**
   * Authenticates the user as a member of the given company
   */
  change(companyId: number): Promise<void>;
};

/**
 * Returns the currently logged in company and helper methods for reloading
 * it's company configuration and to another company of the same user.
 *
 * This hook may only be used in components that are only mounted when a
 * portal user is guaranteed to logged in.
 */
export function useCompany(): Company {
  const dispatch = useDispatch();
  const dialog = useDialog();
  const token = useSelector(
    (state: DefaultRootState) => state.portalAuth.token,
  );
  const company = useSelector(selectPortalCompany);
  const reload = useCallback(async () => {
    if (!company) {
      Sentry.reportError(
        'Tried to reload company when company is missing in state',
      );
      return;
    } else if (!token) {
      Sentry.reportError(
        'Tried to reload company when token is missing in state',
      );
      return;
    }

    const auth = await changeCompany({ ref: token, companyId: company.id });
    const config = await getCompanyConfig(auth.token);

    dispatch(authenticate({ auth, config }));
  }, [token, company]);
  const change = useCallback(
    async (companyId: number) => {
      if (!company) {
        Sentry.reportError(
          'Tried to change company when company is missing in state',
        );
        return;
      } else if (!token) {
        Sentry.reportError(
          'Tried to change company when token is missing in state',
        );
        return;
      }

      const handleAuthentication = async (force: boolean) => {
        const auth = await changeCompany({
          ref: token,
          companyId,
          force,
        });
        const config = await getCompanyConfig(auth.token);
        dispatch(authenticate({ auth, config }));
      };

      const handleConfirm = async () => {
        try {
          await handleAuthentication(true);
        } catch (e) {
          Sentry.reportError('Failed to change company', { error: e });
          throw e;
        }
      };

      try {
        await handleAuthentication(false);
      } catch (e) {
        if (Axios.isError(e) && e.response && Axios.is4xxResponse(e.response)) {
          const message = Axios.extractErrorMessage(e);

          if (message === 'Max number of simultaneous users are logged in') {
            dialog.show((props) => (
              <MultiUserMaxUserDialog {...props} onConfirm={handleConfirm} />
            ));
            return;
          }
        }

        Sentry.reportError('Failed to change company', { error: e });
        throw e;
      }
    },
    [token, company],
  );

  return useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...company!,
      reload,
      change,
    }),
    [company, reload, change],
  );
}
