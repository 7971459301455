import {
  BreakPoints,
  Radii,
  Shadows,
  Spacing,
  Typography,
  ZIndex,
  css,
  Interpolation,
  ExecutionContext,
} from 'styled-components';

/**
 * Shorthand helper functions for styled-components.
 */
export const Styling = {
  prop<P, T extends keyof P>(name: T): (props: P) => P[T] {
    return (props) => props[name];
  },

  typography(
    type: keyof Typography,
  ): (context: ExecutionContext) => Interpolation<object> {
    return (context) => css`
      font-family: ${context.theme.typography[type].fontFamily};
      font-size: ${context.theme.typography[type].fontSize};
      font-weight: ${context.theme.typography[type].fontWeight};
      letter-spacing: ${context.theme.typography[type].letterSpacing};
      line-height: ${context.theme.typography[type].lineHeight};
    `;
  },

  shadow(name: keyof Shadows): (context: ExecutionContext) => string {
    return (context) => context.theme.shadows[name];
  },

  breakpoint(name: keyof BreakPoints): (context: ExecutionContext) => number {
    return (context) => context.theme.breakpoints[name];
  },

  spacing(...names: (keyof Spacing)[]): (context: ExecutionContext) => string {
    return (context) =>
      names.map((name) => context.theme.spacing[name]).join(' ');
  },

  radii(...names: (keyof Radii)[]): (context: ExecutionContext) => string {
    return (context) =>
      names.map((name) => context.theme.radii[name]).join(' ');
  },

  zIndex(name: keyof ZIndex): (context: ExecutionContext) => number {
    return (context) => context.theme.zIndex[name];
  },
};
