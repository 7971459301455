import { wait } from '@idk-web/core-utils';
import { LocalStorage } from '@idk-web/core-ui';
import {
  CodeLoginRequest,
  denmarkPortalLogin,
  finlandPortalLogin,
  initPortalDenmarkLogin,
  initPortalFinlandLogin,
  initPortalNorwayLogin,
  getPortalLoginResult,
  norwayPortalLogin,
  PortalLoginResponse,
  swedenPortalLogin,
} from '@idk-web/api';

const POLL_INTERVAL_MS = 1000;
const POLL_TIMEOUT_MS = 10 * 60 * 1000;

type InitCallback = (data: PortalLoginResponse) => void;
type CompleteCallback = (
  data: Extract<PortalLoginResponse, { status: 'COMPLETED' }>,
) => void;
type ErrorCallback = (error: unknown) => void;
type ProgressCallback = (
  data: Extract<PortalLoginResponse, { status: 'IN_PROGRESS' }>,
) => void;
type InitFunction = () => Promise<PortalLoginResponse>;
type CancelFunction = (ref: string) => Promise<void>;
type GetStatusFunction = (ref: string) => Promise<PortalLoginResponse>;

export type PendingPortalLoginRequest = {
  onInit(callback: InitCallback): PendingPortalLoginRequest;
  onComplete(callback: CompleteCallback): PendingPortalLoginRequest;
  onError(callback: ErrorCallback): PendingPortalLoginRequest;
  onProgress(callback: ProgressCallback): PendingPortalLoginRequest;
  start(): StartedPortalLoginRequest;
};

export type StartedPortalLoginRequest = {
  wait(): Promise<void>;
  cancel(): void;
};

export function password(options: {
  username: string;
  password: string;
}): PendingPortalLoginRequest {
  return start(
    async () => swedenPortalLogin(options),
    async () => {
      // Not supported
    },
    async (ref) => ({
      ref,
      status: 'FAILED',
      error: "Fetched status when init should've been sufficient",
    }),
  );
}

export function sweden(): PendingPortalLoginRequest {
  return start(
    swedenPortalLogin,
    async () => {
      // Not supported
    },
    getPortalLoginResult,
  );
}

export function norway(window: Window): PendingPortalLoginRequest {
  return startExternal(window, initPortalNorwayLogin, norwayPortalLogin);
}

export function denmark(window: Window): PendingPortalLoginRequest {
  return startExternal(window, initPortalDenmarkLogin, denmarkPortalLogin);
}

export function finland(window: Window): PendingPortalLoginRequest {
  return startExternal(window, initPortalFinlandLogin, finlandPortalLogin);
}

function startExternal(
  window: Window,
  init: () => Promise<Extract<PortalLoginResponse, { status: 'IN_PROGRESS' }>>,
  login: (
    req: CodeLoginRequest,
  ) => Promise<Extract<PortalLoginResponse, { status: 'COMPLETED' }>>,
): PendingPortalLoginRequest {
  return start(
    async () => {
      const { ref, url } = await init();
      window.location.href = url ?? '';
      return { status: 'IN_PROGRESS', ref };
    },
    async () => window.close(),
    async (ref) => {
      const code = LocalStorage.getItem('code');

      if (code) {
        LocalStorage.removeItem('code');
      } else if (window.closed) {
        return { status: 'FAILED', error: 'cancelled', ref };
      } else {
        return { status: 'IN_PROGRESS', ref };
      }

      return login({ id: ref, code });
    },
  );
}

function start(
  init: InitFunction,
  cancel: CancelFunction,
  getStatus: GetStatusFunction,
): PendingPortalLoginRequest {
  const initCallbacks: InitCallback[] = [];
  const completeCallbacks: CompleteCallback[] = [];
  const errorCallbacks: ErrorCallback[] = [];
  const progressCallbacks: ProgressCallback[] = [];
  const controller = new AbortController();

  return {
    onInit(callback) {
      initCallbacks.push(callback);
      return this;
    },
    onComplete(callback) {
      completeCallbacks.push(callback);
      return this;
    },
    onError(callback) {
      errorCallbacks.push(callback);
      return this;
    },
    onProgress(callback) {
      progressCallbacks.push(callback);
      return this;
    },
    start() {
      const promise = run(
        init,
        cancel,
        getStatus,
        (data) => initCallbacks.forEach((f) => f(data)),
        (data) => completeCallbacks.forEach((f) => f(data)),
        (error) => errorCallbacks.forEach((f) => f(error)),
        (data) => progressCallbacks.forEach((f) => f(data)),
        controller,
      );

      return {
        wait: () => promise,
        cancel: () => controller.abort(),
      };
    },
  };
}

async function run(
  init: InitFunction,
  cancel: CancelFunction,
  getStatus: GetStatusFunction,
  onInit: InitCallback,
  onComplete: CompleteCallback,
  onError: ErrorCallback,
  onProgress: ProgressCallback,
  controller: AbortController,
): Promise<void> {
  let timeLeft = POLL_TIMEOUT_MS;
  let ref: string;

  try {
    const response = await init();
    ref = response.ref;

    onInit(response);

    switch (response.status) {
      case 'FAILED':
        throw new Error(response.error);
      case 'COMPLETED': {
        onComplete(response);
        return;
      }
    }
  } catch (e) {
    onError(e);
    throw e;
  }

  controller.signal.addEventListener('abort', () => cancel(ref));

  while (timeLeft > 0) {
    let response: PortalLoginResponse;
    try {
      response = await getStatus(ref);
    } catch (e: unknown) {
      onError(e);
      throw e;
    }

    if (controller.signal.aborted || timeLeft < 0) {
      break;
    }

    switch (response.status) {
      case 'FAILED':
        onError(response.error);
        throw new Error(response.error);
      case 'COMPLETED': {
        onComplete(response);
        return;
      }
    }

    timeLeft -= POLL_INTERVAL_MS;

    onProgress(response);

    await wait(POLL_INTERVAL_MS);

    if (controller.signal.aborted || timeLeft < 0) {
      break;
    }
  }

  if (controller.signal.aborted) {
    onError('cancelled');
    throw new Error('cancelled');
  } else {
    onError('timed out');
    throw new Error('timed out');
  }
}
