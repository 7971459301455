import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC, ReactNode, useMemo } from 'react';
import { showReportDialog, lastEventId } from '@sentry/react';
import { Styling } from '@/styling';
import { DialogProps } from '@/hooks/useDialog';
import { Box } from '@/components/layout/Box';
import { DangerButton } from '@/components/input/Button';
import { StandardCard } from '@/components/card/standard';

const DialogCard = styled(StandardCard)`
  border-top: 8px solid ${({ theme }) => theme.palette.error.main};
  margin: 0;
  max-width: 500px;
`;

const TextSection = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[2],
}))``;

const ButtonSection = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[1],
}))`
  margin-top: ${Styling.spacing(4)};
`;

const Header = styled.h2`
  ${Styling.typography('h2')};
  color: ${({ theme }) => theme.palette.error.main};
`;

const Text = styled.div`
  ${Styling.typography('body')};
  color: #888888; // TODO move to theme
`;

const FeedbackButton = styled.button.attrs({
  type: 'button',
})`
  ${Styling.typography('body')}
  padding: ${Styling.spacing(0, 2)};
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.main};
  background-color: transparent;
  border: none;
  outline: none;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export type ErrorDialogProps = {
  header?: ReactNode;
  text: ReactNode;
  okText?: string;
  onOk?(): void;
} & DialogProps;

export const ErrorDialog: FC<ErrorDialogProps> = ({
  header,
  text,
  okText,
  onOk,
  onClose,
}) => {
  const { t } = useTranslation();
  const eventId = useMemo(() => lastEventId(), []);

  const handleFeedback = () => {
    onClose?.();
    onClose?.();
    showReportDialog({
      eventId,
      title: t('error.feedback.title'),
      subtitle: t('error.feedback.subtitle'),
      subtitle2: t('error.feedback.subtitle2'),
      labelName: t('error.feedback.labelName'),
      labelEmail: t('error.feedback.labelEmail'),
      labelComments: t('error.feedback.labelComments'),
      labelClose: t('error.feedback.labelClose'),
      labelSubmit: t('error.feedback.labelSubmit'),
      errorGeneric: t('error.feedback.errorGeneric'),
      errorFormEntry: t('error.feedback.errorFormEntry'),
      successMessage: t('error.feedback.successMessage'),
    });
  };

  const handleOk = () => {
    onOk?.();
    onClose?.();
  };

  return (
    <DialogCard>
      <TextSection>
        <Header>{header ?? t('errors.an_error_occurred')}</Header>
        <Text>{text}</Text>
      </TextSection>
      <ButtonSection>
        <DangerButton text={okText ?? t('common.ok')} onClick={handleOk} />
        {eventId && (
          <FeedbackButton onClick={handleFeedback}>
            {t('error.feedback.button')}
          </FeedbackButton>
        )}
      </ButtonSection>
    </DialogCard>
  );
};
