import { styled } from 'styled-components';
import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { Styling } from '@/styling';
import { flexGap } from '@/flexGap';
import { Divider } from '@/components/visual/Divider';
import { Typography } from '@/components/text/Typography';
import { Sensitive } from '@/components/text/Sensitive';

const Container = styled.div`
  position: relative;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  padding: 0 ${Styling.spacing(1.5)};
  ${({ theme }) => flexGap(theme.spacing[1])};
  background-color: ${({ theme }) => theme.palette.grayscale.light5};
  color: black;
  border-radius: ${Styling.radii('button')};
  cursor: pointer;
`;

const ProfileIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding-top: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.grayscale.light3};
  color: ${({ theme }) => theme.palette.grayscale.main};
  overflow: hidden;
`;

const Text = styled.div`
  ${Styling.typography('body')};
  white-space: nowrap;
`;

const ExpandCollapse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  padding-top: 1px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.grayscale.white};
  border-radius: 50%;
`;

const Menu = styled.ul`
  position: absolute;
  min-width: 100%;
  margin-top: -1px;
  top: 100%;
  right: 0;
  background-color: ${({ theme }) => theme.palette.grayscale.white};
  box-shadow: 0 8px 16px 0 #7e7f805c;
  border-radius: ${Styling.radii('button', 0, 'button', 'button')};
  overflow: hidden;
`;

const MenuItem = styled.li`
  color: ${({ theme }) => theme.palette.primary.text};
  padding: ${Styling.spacing(1.5, 2)};
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grayscale.light5};
  }
`;

export type ProfileMenuProps = {
  username: string;
  items: (
    | {
        content: ReactNode;
        onClick?(): void;
      }
    | 'divider'
  )[];
};

export const ProfileMenu: FC<ProfileMenuProps> = ({ username, items }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        isOpen &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    },
    [isOpen, containerRef.current],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleClick]);

  return (
    <Container ref={containerRef}>
      <Button onClick={() => setIsOpen((open) => !open)}>
        <ProfileIcon>
          <FontAwesomeIcon icon={faUser} size="lg" />
        </ProfileIcon>
        <Text>
          <Sensitive>{username}</Sensitive>
        </Text>
        <ExpandCollapse>
          <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} size="xs" />
        </ExpandCollapse>
      </Button>
      {isOpen && (
        <Menu>
          <Typography type="body">
            {items.map((item, index) => {
              if (item === 'divider') {
                return (
                  <Fragment key={index}>
                    <Divider orientation="horizontal" />
                  </Fragment>
                );
              }

              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setIsOpen(false);
                    item.onClick?.();
                  }}
                >
                  {item.content}
                </MenuItem>
              );
            })}
          </Typography>
        </Menu>
      )}
    </Container>
  );
};
