import { styled } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SortFn } from '@idk-web/core-utils';
import { Styling } from '@idk-web/core-ui';
import { changeLanguage, selectLanguage } from '@/redux/locale.slice';
import UK from '@/assets/svg/uk_rect.svg';
import SE from '@/assets/svg/se_rect.svg';
import NO from '@/assets/svg/no_rect.svg';
import FI from '@/assets/svg/fi_rect.svg';
import DK from '@/assets/svg/dk_rect.svg';

export type Language = {
  name: string;
  locale: string;
  icon: ReactNode;
};

const Button = styled.div`
  position: relative;
  width: 42px;
  height: 42px;
`;

const List = styled.ul<{ open: boolean }>`
  position: absolute;
  background-color: ${({ theme, open }) =>
    open ? theme.palette.grayscale.white : theme.palette.grayscale.light5};
  border-radius: 18px; // Button.height / 2
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition:
    width 50ms,
    height 50ms,
    border-radius 50ms;
  box-shadow: ${({ open }) =>
    open ? '0px 8px 16px 0px #7E7F805C' : 'none'}; // TODO move to theme
  cursor: pointer;

  &:hover {
    border-radius: ${Styling.radii('button')};
  }
`;

const ListItem = styled.li`
  padding: 0 ${Styling.spacing(1)};
  height: 42px; // Same as Button
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grayscale.light5};
  }
`;

const Text = styled.span`
  flex-shrink: 0;
  white-space: nowrap;
  padding-left: ${Styling.spacing(1)};
  padding-right: ${Styling.spacing(1.5)};
`;

const Icon = styled.img.attrs({
  width: 28,
  height: 16,
})`
  flex-shrink: 0;
  height: 16px;
`;

/**
 * Returns a sort function which puts the language with the given locale
 * first and leaves every other element as-is.
 */
function putOnTop(language: Language): SortFn<Language> {
  return (a: Language, b: Language) => {
    if (a.locale === language.locale) {
      return -1;
    } else if (b.locale === language.locale) {
      return 1;
    } else {
      return 0;
    }
  };
}

export const ENGLISH: Language = {
  name: 'English',
  locale: 'en',
  icon: <Icon src={UK} alt="English" />,
};

export const SWEDISH: Language = {
  name: 'Svenska',
  locale: 'sv',
  icon: <Icon src={SE} alt="Svenska" />,
};

export const NORWEGIAN: Language = {
  name: 'Norsk',
  locale: 'no',
  icon: <Icon src={NO} alt="Norsk" />,
};

export const DANISH: Language = {
  name: 'Dansk',
  locale: 'dk',
  icon: <Icon src={DK} alt="Dansk" />,
};

export const FINNISH: Language = {
  name: 'Suomi',
  locale: 'fi',
  icon: <Icon src={FI} alt="Suomi" />,
};

const LANGUAGES: Language[] = [ENGLISH, SWEDISH, NORWEGIAN, DANISH, FINNISH];

const LanguageSelect: FC = () => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLanguage);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const language = useMemo(
    () => LANGUAGES.find((lang) => lang.locale === locale) ?? LANGUAGES[0],
    [locale],
  );
  const languages = useMemo(
    () => LANGUAGES.slice().sort(putOnTop(language)),
    [language],
  );
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        isOpen &&
        listRef.current &&
        !listRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    },
    [isOpen, listRef.current],
  );

  useEffect(() => {
    if (!isHovering) {
      setIsOpen(false);
    }
  }, [isHovering]);

  useEffect(() => {
    if (listRef.current) {
      if (isHovering || isOpen) {
        listRef.current.style.width = `${listRef.current.scrollWidth}px`;
      } else {
        listRef.current.style.width = '100%';
      }

      if (isOpen) {
        listRef.current.style.height = `${listRef.current.scrollHeight}px`;
      } else {
        listRef.current.style.height = '100%';
      }
    }
  }, [listRef.current, isHovering, isOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleClick]);

  const handleClickItem = (language: Language) => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      dispatch(changeLanguage(language.locale));
    }
  };

  return (
    <Button>
      <List
        ref={listRef}
        open={isOpen}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {languages.map((lang) => (
          <ListItem key={lang.name} onClick={() => handleClickItem(lang)}>
            {lang.icon}
            <Text>{lang.name}</Text>
          </ListItem>
        ))}
      </List>
    </Button>
  );
};

export default LanguageSelect;
