import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { DialogProps, ConfirmDialog } from '@idk-web/core-ui';

export type ChangeCompanyDialogProps = {
  onOk?(): void;
  onCancel?(): void;
} & DialogProps;

const ChangeCompanyDialog: FC<ChangeCompanyDialogProps> = (props) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      header={t('portal.header.menu.change_company.are_you_sure')}
      okText={t('portal.header.menu.change_company.confirm')}
      {...props}
    />
  );
};

export default ChangeCompanyDialog;
