import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import type { ValueOf } from '@idk-web/core-utils';
import sv from '@/i18n/sv.json';
import no from '@/i18n/no.json';
import fi from '@/i18n/fi.json';
import en from '@/i18n/en.json';
import dk from '@/i18n/dk.json';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const translations = { en, sv, no, fi, dk };

export type Translation = ValueOf<typeof translations>;

export function initialize(): void {
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: en },
      sv: { translation: sv },
      no: { translation: no },
      fi: { translation: fi },
      dk: { translation: dk },
    },
    lng: 'en',
  });
}
