import { css, Interpolation } from 'styled-components';

/**
 * Returns styled components CSS interpolation for `gap: x;` or a polyfill replacement in case it is
 * not supported by the browser (such as in Safari < 14.1).
 */
export function flexGap(gap: string | number): Interpolation<object> {
  if (isFlexGapSupported()) {
    return css`
      gap: ${gap};
    `;
  }

  return polyfill(gap);
}

let flexGapSupported: boolean | null = null;

export function isFlexGapSupported(): boolean {
  if (flexGapSupported !== null) {
    return flexGapSupported;
  }

  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));
  document.body.appendChild(flex);

  flexGapSupported = flex.scrollHeight === 1;
  flex?.parentNode?.removeChild(flex);

  return flexGapSupported;
}

function polyfill(gap: string | number): Interpolation<object> {
  return css`
    margin-left: -${gap};
    margin-top: -${gap};

    & > * {
      margin-left: ${gap};
      margin-top: ${gap};
    }
  `;
}
