import { styled, keyframes, useTheme } from 'styled-components';
import { TailSpin } from 'react-loader-spinner';
import React, { FC } from 'react';
import { range } from '@idk-web/core-utils';
import { Styling } from '@/styling';
import { Box } from '@/components/layout/Box';

export const SpinningCircleLoader: FC = () => {
  const theme = useTheme();

  return (
    <Box alignX="center">
      <TailSpin
        width={`64px`}
        height={`64px`}
        color={theme.palette.primary.main}
        ariaLabel="loading-indicator"
      />
    </Box>
  );
};

const blink = keyframes`
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.1;
  }
`;

const Bar = styled.span<BlinkingBarsLoaderProps>`
  width: 10px;
  height: 18px;
  opacity: 0.1;
  background: white;
  border-radius: 4px;
  animation: ${blink} ${Styling.prop('time')}s infinite;
`;

export type BlinkingBarsLoaderProps = {
  time?: number;
};

export const BlinkingBarsLoader: FC<BlinkingBarsLoaderProps> = ({
  time = 1,
}) => {
  return (
    <Box alignX="center" spacing="6px">
      {range(0, 8).map((index: number) => (
        <Bar
          key={index}
          time={time}
          style={{ animationDelay: `${(time / 9) * index}s` }}
        />
      ))}
    </Box>
  );
};
