import { DefaultRootState, PortalFilterState } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: PortalFilterState = {
  usersMessagesPerPage: 10,
  inboxMessagesPerPage: 10,
  outboxMessagesPerPage: 10,
  teleIdLogsPerPage: 10,
  teleSignLogsPerPage: 10,
};

export const portalFilterSlice = createSlice({
  name: 'portal/filter',
  initialState: initialState as PortalFilterState,
  reducers: {
    setUsersPerPage(state, action: PayloadAction<number>): void {
      state.usersMessagesPerPage = action.payload;
    },
    setInboxMessagesPerPage(state, action: PayloadAction<number>): void {
      state.inboxMessagesPerPage = action.payload;
    },
    setOutboxMessagesPerPage(state, action: PayloadAction<number>): void {
      state.outboxMessagesPerPage = action.payload;
    },
    setTeleIdLogsPerPage(state, action: PayloadAction<number>): void {
      state.teleIdLogsPerPage = action.payload;
    },
    setTeleSignLogsPerPage(state, action: PayloadAction<number>): void {
      state.teleSignLogsPerPage = action.payload;
    },
  },
});

export const {
  setUsersPerPage,
  setInboxMessagesPerPage,
  setOutboxMessagesPerPage,
  setTeleIdLogsPerPage,
  setTeleSignLogsPerPage,
} = portalFilterSlice.actions;

export const selectUsersPerPage = (state: DefaultRootState) =>
  state.portalFilter.usersMessagesPerPage;
export const selectInboxMessagesPerPage = (state: DefaultRootState) =>
  state.portalFilter.inboxMessagesPerPage;
export const selectOutboxMessagesPerPage = (state: DefaultRootState) =>
  state.portalFilter.outboxMessagesPerPage;
export const selectTeleIdLogsPerPage = (state: DefaultRootState) =>
  state.portalFilter.teleIdLogsPerPage;
export const selectTeleSignLogsPerPage = (state: DefaultRootState) =>
  state.portalFilter.teleSignLogsPerPage;

export default portalFilterSlice.reducer;
