import { Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import { SentryRoute } from '@/sentry';
import {
  selectPortalLoginStatus,
  selectPortalSso,
} from '@/redux/portalAuth.slice';
import routes from '@/components/portal/routes';

export type AuthenticatedRouteProps = RouteProps;

/**
 * A route which redirects to the login page if the current user is logged out.
 */
const AuthenticatedRoute: FC<AuthenticatedRouteProps> = (props) => {
  const loginStatus = useSelector(selectPortalLoginStatus);
  const [registrationId, setRegistrationId] = useState<string | null>(null);
  const sso = useSelector(selectPortalSso);

  useEffect(() => {
    if (sso) {
      setRegistrationId(sso.registrationId);
    }
  }, [sso]);

  useEffect(() => {
    if (loginStatus === 'LOGGED_OUT') {
      setRegistrationId(null);
    }
  }, [loginStatus]);

  if (loginStatus === 'LOGGED_OUT') {
    if (registrationId) {
      return (
        <Redirect
          to={routes.sessionExpiredSso.replace(
            ':registrationId',
            registrationId,
          )}
        />
      );
    }

    return <Redirect to={routes.login} />;
  }

  return <SentryRoute {...props} />;
};

export default AuthenticatedRoute;
