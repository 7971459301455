import { Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import { PortalTab } from '@idk-web/api';
import { SentryRoute } from '@/sentry';
import {
  selectPortalCompany,
  selectPortalLoginStatus,
} from '@/redux/portalAuth.slice';
import routes from '@/components/portal/routes';

export type UnauthenticatedRouteProps = RouteProps;

/**
 * A route which redirects to the portal app if the current user is logged in.
 */
const UnauthenticatedRoute: FC<UnauthenticatedRouteProps> = (props) => {
  const loginStatus = useSelector(selectPortalLoginStatus);
  const company = useSelector(selectPortalCompany);

  if (company && loginStatus === 'AUTHENTICATED') {
    switch (company.defaultStartTab) {
      case PortalTab.TELESIGN:
        return <Redirect to={routes.telesign} />;
      case PortalTab.SAFEMAIL:
        return <Redirect to={routes.safemail} />;
      case PortalTab.ADMIN:
        return <Redirect to={routes.admin} />;
      default:
        return <Redirect to={routes.teleid} />;
    }
  }

  return <SentryRoute {...props} />;
};

export default UnauthenticatedRoute;
