import axios, { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

export type Page<T> = {
  data: T[];
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
};

export async function getVersionHeaders(
  signal?: AbortSignal,
): Promise<RawAxiosResponseHeaders | AxiosResponseHeaders> {
  return axios.head('/', { signal }).then((response) => response.headers);
}
