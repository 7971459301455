import React, {
  Component,
  ErrorInfo,
  FC,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { Sentry } from '@idk-web/core-utils';

export type ErrorBoundaryProps = PropsWithChildren<{
  fallback: FC<{ error: unknown }>;
}>;

type ErrorBoundaryState = {
  error: unknown | null;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: unknown): ErrorBoundaryState {
    return { error };
  }

  componentDidCatch(error: unknown, info: ErrorInfo): void {
    Sentry.reportError('Error boundary hit', { error: { error, info } });
  }

  render(): ReactNode {
    if (this.state.error !== null) {
      const Fallback = this.props.fallback;

      return <Fallback error={this.state.error} />;
    }

    return this.props.children;
  }
}
