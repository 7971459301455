import { DefaultRootState } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { debounce, Sentry } from '@idk-web/core-utils';
import { LocalStorage } from '@idk-web/core-ui';
import reducer from '@/redux/reducer';

const STORAGE_KEY = 'idk-portal-state';
const PERSIST_INTERVAL_MS = 1000;

function readState() {
  const state = LocalStorage.getItem(STORAGE_KEY);

  try {
    return state ? JSON.parse(state) : undefined;
  } catch (e: unknown) {
    Sentry.reportError('Failed to parse redux state', { error: e });
    return undefined;
  }
}

function persistState(state: DefaultRootState) {
  LocalStorage.setItem(STORAGE_KEY, JSON.stringify(state));
}

const store = configureStore({
  reducer,
  preloadedState: readState(),
});

store.subscribe(
  debounce(() => {
    persistState(store.getState());
  }, PERSIST_INTERVAL_MS),
);

export default store;
