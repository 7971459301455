export type Hostname = {
  subdomain: string | null;
  domain: string;
  tld: string | null;
};

let subdomain;
let domain;
let tld;

const elements = window.location.hostname.split('.');

// hostname can be either <app>.<domain>.<tld> or <env>-<app>.<domain>.<tld>
if (elements.length > 2) {
  // <subdomain>.<domain>.<tld>
  subdomain = elements[0] ?? null;
  domain = elements[1] ?? '';
  tld = elements[2] ?? null;
} else if (elements.length === 2 && elements[1] === 'localhost') {
  // <subdomain>.localhost
  subdomain = elements[0] ?? null;
  domain = 'localhost';
  tld = 'se';
} else {
  // <domain>.<tld>
  subdomain = null;
  domain = elements[0] ?? 'localhost';
  tld = elements[1] ?? null;
}

export const hostname: Hostname = { subdomain, domain, tld };
