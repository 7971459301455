import { combineReducers } from 'redux';
import portalFilter from '@/redux/portalFilter.slice';
import portalAuth from '@/redux/portalAuth.slice';
import locale from '@/redux/locale.slice';

export default combineReducers({
  locale,
  portalAuth,
  portalFilter,
});
