export function capitalize(text: string): string {
  return text
    .split('.')
    .map((line) => line.charAt(0).toUpperCase() + line.slice(1).toLowerCase())
    .join();
}

export function titleCase(text: string): string {
  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function hashCode(text: string): number {
  let hash = 0;

  for (let i = 0, len = text.length; i < len; i++) {
    const chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return hash;
}
