import { styled, css, keyframes, useTheme } from 'styled-components';
import { TailSpin } from 'react-loader-spinner';
import React, {
  ButtonHTMLAttributes,
  ComponentType,
  DetailedHTMLProps,
  FC,
  ReactNode,
} from 'react';
import { Styling } from '@/styling';
import { flexGap } from '@/flexGap';
import { BlinkingBarsLoader } from '@/components/visual/Loader';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 0;
  top: -12px;
  animation: ${fadeIn} 200ms linear 1;
`;

const Loader: FC = () => {
  const theme = useTheme();

  return (
    <LoaderWrapper>
      <TailSpin
        width={`24px`}
        height={`24px`}
        color={theme.palette.secondary.light2}
        ariaLabel="loading-indicator"
      />
    </LoaderWrapper>
  );
};

const ButtonBase = styled.button`
  ${Styling.typography('button')}
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => flexGap(theme.spacing[2])};
  text-transform: uppercase;
  border: 0;
  border-radius: ${Styling.radii('button')};
  cursor: pointer;
  transition:
    color 80ms,
    background-color 80ms,
    border 80ms;
  min-width: 100px;

  &.lg {
    height: 56px;
    padding: 0 ${Styling.spacing(6)};
  }

  &.md {
    height: 44px;
    padding: 0 ${Styling.spacing(4)};
  }

  &.sm {
    height: 32px;
    padding: 0 ${Styling.spacing(2)};
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

export type ButtonProps = {
  size?: 'sm' | 'md' | 'lg';
  text?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: string | ComponentType<any>;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  loading?: boolean;
  loadingText?: ReactNode;
  loader?: ReactNode;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button: FC<ButtonProps> = ({
  className = '',
  size = 'md',
  disabled,
  loading,
  loadingText,
  loader,
  startAdornment,
  endAdornment,
  text,
  ...props
}) => {
  const getText = () => {
    if (loading) {
      return loadingText ?? text;
    }

    return text;
  };

  className += ' ' + size;

  if (loading) {
    className += ' ' + 'loading';
  }

  return (
    <ButtonBase className={className} disabled={disabled || loading} {...props}>
      {loading && (loader ?? <Loader />)}
      {startAdornment}
      {getText()}
      {endAdornment}
    </ButtonBase>
  );
};

const disabledButtonStyle = css`
  &:disabled,
  &.loading {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.palette.disabled.main};
    color: ${({ theme }) => theme.palette.disabled.text};
    border: 1px solid ${({ theme }) => theme.palette.disabled.dark1};

    &:hover {
      background-color: ${({ theme }) => theme.palette.disabled.main};
    }
  }
`;

export const PrimaryButton = styled(Button)`
  color: ${({ theme }) => theme.palette.grayscale.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light1};
    border: 1px solid ${({ theme }) => theme.palette.primary.light1};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  ${disabledButtonStyle}
`;

export const SecondaryButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.grayscale.white};
  border: 1px solid white;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light5};
    border: 1px solid ${({ theme }) => theme.palette.primary.light5};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.primary.light4};
    border: 1px solid ${({ theme }) => theme.palette.primary.light4};
  }

  &.loading {
    background-color: white !important;
    border: 1px solid white !important;
  }

  ${disabledButtonStyle}
`;

export const GrayButton = styled(Button)`
  color: ${({ theme }) => theme.palette.grayscale.white};
  background-color: ${({ theme }) => theme.palette.grayscale.dark1};
  border: 1px solid ${({ theme }) => theme.palette.grayscale.dark1};

  &:hover {
    background-color: ${({ theme }) => theme.palette.grayscale.main};
    border: 1px solid ${({ theme }) => theme.palette.grayscale.main};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.grayscale.dark1};
    border: 1px solid ${({ theme }) => theme.palette.grayscale.dark1};
  }

  ${disabledButtonStyle}
`;

export const TertiaryButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.primary.light5};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light4};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.primary.light3};
  }

  ${disabledButtonStyle}
`;

export const AlternativeButton = styled(Button)`
  color: ${({ theme }) => theme.palette.grayscale.white};
  background-color: #116fab;
  border: 1px solid #116fab;
  &:hover {
    background-color: #1c7cb9;
    border: 1px solid #1c7cb9;
  }

  &:active {
    background-color: #115886;
    border: 1px solid #115886;
  }

  &:focus {
    outline: 2px solid #3db5fd;
  }

  ${disabledButtonStyle}
`;

export const OkButton = styled(Button)`
  color: ${({ theme }) => theme.palette.grayscale.white};
  background-color: ${({ theme }) => theme.palette.success.main};
  border: 1px solid ${({ theme }) => theme.palette.success.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.success.light1};
    border: 1px solid ${({ theme }) => theme.palette.success.light1};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.success.dark1};
    border: 1px solid ${({ theme }) => theme.palette.success.dark1};
  }

  ${disabledButtonStyle}
`;

export const DangerButton = styled(Button)`
  color: ${({ theme }) => theme.palette.grayscale.white};
  background-color: ${({ theme }) => theme.palette.error.main};
  border: 1px solid ${({ theme }) => theme.palette.error.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.error.light1};
    border: 1px solid ${({ theme }) => theme.palette.error.light1};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.error.dark1};
    border: 1px solid ${({ theme }) => theme.palette.error.dark1};
  }

  ${disabledButtonStyle}
`;

export const DownloadButton = styled(Button)`
  color: #558cc9;
  background-color: #edf3f9;
  border: 2px dashed #558cc9;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.grayscale.white};
    border: 2px dashed ${({ theme }) => theme.palette.primary.main};
  }

  &:active {
    background-color: #edf3f9;
  }

  ${disabledButtonStyle}
`;

export const SecureButton = styled(Button).attrs({
  loadingText: '',
  loader: <BlinkingBarsLoader />,
})`
  color: ${({ theme }) => theme.palette.grayscale.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light1};
    border: 1px solid ${({ theme }) => theme.palette.primary.light1};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &:disabled,
  &.loading {
    cursor: not-allowed;
  }

  &:disabled:not(.loading) {
    background-color: ${({ theme }) => theme.palette.disabled.main};
    color: ${({ theme }) => theme.palette.disabled.text};
    border: 1px solid ${({ theme }) => theme.palette.disabled.dark1};

    &:hover {
      background-color: ${({ theme }) => theme.palette.disabled.main};
    }
  }
`;
