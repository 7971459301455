import { styled } from 'styled-components';
import { Styling } from '@/styling';

export const StandardCard = styled.div`
  background-color: ${({ theme }) => theme.palette.grayscale.white};
  border-radius: ${Styling.radii('card')};
  box-shadow: ${Styling.shadow('card')};
  padding: ${Styling.spacing(6)};
  margin: ${Styling.spacing(6)};
  width: 100%;
  @media (max-width: ${Styling.breakpoint('lg')}px) {
    padding: ${Styling.spacing(4)};
    margin: ${Styling.spacing(4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    padding: ${Styling.spacing(2)};
    margin: ${Styling.spacing(2)};
  }
`;
