import { styled, useTheme } from 'styled-components';
import React, {
  ComponentType,
  DetailedHTMLProps,
  FC,
  Ref,
  InputHTMLAttributes,
  ReactNode,
  useState,
  useRef,
} from 'react';
import { Styling } from '@/styling';

type State = {
  disabled?: boolean;
  valid?: boolean;
  focus?: boolean;
};

const RoundTextFieldBase = styled.div<State>`
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  color: ${({ theme }) => theme.palette.primary.text};
  background-color: ${({ theme, disabled }) =>
    disabled ? 'rgba(255, 255, 255, 0.5)' : theme.palette.grayscale.white};
  border-radius: ${Styling.radii('input')};
  border: ${({ theme, focus, valid }) => {
    if (focus) {
      return 'none';
    }

    if (valid === true) {
      return `2px solid ${theme.palette.success.main} !important`;
    } else if (valid === false) {
      return `2px solid ${theme.palette.error.main} !important`;
    } else {
      return 'none';
    }
  }};
  outline: ${({ theme, focus }) =>
    focus ? `1px solid ${theme.palette.primary.main}` : 0};

  &:hover {
    outline: ${({ theme, focus }) =>
      focus ? undefined : `1px solid ${theme.palette.grayscale.light2}`};
  }
`;

const UnderlinedTextFieldBase = styled.div<State>`
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  color: ${({ theme }) => theme.palette.primary.text};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grayscale.light5 : theme.palette.grayscale.white};
  border-top-left-radius: ${Styling.radii('input')};
  border-top-right-radius: ${Styling.radii('input')};
  border-bottom: ${({ theme, valid }) => {
    if (valid === true) {
      return `2px solid ${theme.palette.success.main} !important`;
    } else if (valid === false) {
      return `2px solid ${theme.palette.error.main} !important`;
    } else {
      return `2px solid ${theme.palette.grayscale.light5}`;
    }
  }};

  &:hover {
    border-bottom: ${({ theme, disabled }) =>
      disabled ? undefined : `2px solid ${theme.palette.grayscale.light4}`};
  }
`;

const FilledTextFieldBase = styled.div<State>`
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  color: ${({ theme }) => theme.palette.primary.text};
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.palette.grayscale.light5 + '7F'
      : theme.palette.grayscale.light5};
  border-radius: ${Styling.radii('input')};
  border: ${({ theme, focus, valid }) => {
    if (focus) {
      return 'none';
    }

    if (valid === true) {
      return `2px solid ${theme.palette.success.main} !important`;
    } else if (valid === false) {
      return `2px solid ${theme.palette.error.main} !important`;
    } else {
      return 'none';
    }
  }};
  outline: ${({ theme, focus }) =>
    focus ? `1px solid ${theme.palette.primary.main}` : 0};
  transition: 100ms border;

  &:hover {
    outline: ${({ theme, focus }) =>
      focus ? undefined : `1px solid ${theme.palette.grayscale.light2}`};
  }
`;

const TextFieldInput = styled.input<{ backgroundColor: string }>`
  ${Styling.typography('input')}
  padding: ${Styling.spacing(2)};
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: inherit;

  &:disabled {
    cursor: not-allowed;
  }

  // Hack to hide Chrome's autofill styling
  &:-webkit-autofill,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px ${Styling.prop('backgroundColor')} inset;
  }
`;

const Adornment = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.palette.grayscale.main};
`;

const StartAdornment = styled(Adornment)`
  flex-shrink: 0;
  margin-left: ${Styling.spacing(2)};
`;

const EndAdornment = styled(Adornment)`
  flex-shrink: 0;
  margin-right: ${Styling.spacing(2)};
`;

export type TextFieldProps = {
  innerRef?: Ref<HTMLInputElement>;
  className?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  valid?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: string | ComponentType<any>;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const RoundTextField: FC<TextFieldProps> = ({
  className,
  startAdornment,
  endAdornment,
  valid,
  innerRef,
  ...props
}) => {
  const theme = useTheme();
  const [hasFocus, setHasFocus] = useState(false);
  // Hack to match the input autofill styling with the background color of the
  // wrapper (even if overridden)
  const ref = useRef<HTMLDivElement>(null);
  const backgroundColor = ref.current
    ? window.getComputedStyle(ref.current).backgroundColor
    : theme.palette.grayscale.light5;

  return (
    <RoundTextFieldBase
      ref={ref}
      disabled={props.disabled}
      valid={valid}
      focus={hasFocus}
      className={className}
    >
      {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
      <TextFieldInput
        ref={innerRef}
        type="text"
        backgroundColor={backgroundColor}
        startAdornment={Boolean(startAdornment)}
        endAdornment={Boolean(endAdornment)}
        {...props}
        onFocus={(evt: React.FocusEvent<HTMLInputElement>) => {
          setHasFocus(true);
          props.onFocus?.(evt);
        }}
        onBlur={(evt: React.FocusEvent<HTMLInputElement>) => {
          setHasFocus(false);
          props.onBlur?.(evt);
        }}
      />
      {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
    </RoundTextFieldBase>
  );
};

type UnderlineProps = {
  visible?: boolean;
};

const Underline = styled.div<UnderlineProps>`
  width: ${({ visible }) => (visible ? '100%' : '0%')};
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.palette.primary.main};
  transition: width 120ms;
`;

export const UnderlinedTextField: FC<TextFieldProps> = ({
  className,
  startAdornment,
  endAdornment,
  valid,
  innerRef,
  ...props
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  // Hack to match the input autofill styling with the background color of the
  // wrapper (even if overridden)
  const ref = useRef<HTMLDivElement>(null);
  const backgroundColor = ref.current
    ? window.getComputedStyle(ref.current).backgroundColor
    : 'white';

  return (
    <UnderlinedTextFieldBase
      ref={ref}
      disabled={props.disabled}
      valid={valid}
      focus={hasFocus}
      className={className}
    >
      {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
      <TextFieldInput
        ref={innerRef}
        type="text"
        backgroundColor={backgroundColor}
        startAdornment={Boolean(startAdornment)}
        endAdornment={Boolean(endAdornment)}
        {...props}
        onFocus={(evt: React.FocusEvent<HTMLInputElement>) => {
          setHasFocus(true);
          props.onFocus?.(evt);
        }}
        onBlur={(evt: React.FocusEvent<HTMLInputElement>) => {
          setHasFocus(false);
          props.onBlur?.(evt);
        }}
      />
      {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
      <Underline visible={hasFocus} />
    </UnderlinedTextFieldBase>
  );
};

// TODO treat valid like before
export const FilledTextField: FC<TextFieldProps> = ({
  className,
  startAdornment,
  endAdornment,
  valid,
  innerRef,
  ...props
}) => {
  const theme = useTheme();
  const [hasFocus, setHasFocus] = useState(false);
  // Hack to match the input autofill styling with the background color of the
  // wrapper (even if overridden)
  const ref = useRef<HTMLDivElement>(null);
  const backgroundColor = ref.current
    ? window.getComputedStyle(ref.current).backgroundColor
    : theme.palette.grayscale.light5;

  return (
    <FilledTextFieldBase
      ref={ref}
      disabled={props.disabled}
      valid={valid}
      focus={hasFocus}
      className={className}
    >
      {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
      <TextFieldInput
        ref={innerRef}
        type="text"
        backgroundColor={backgroundColor}
        startAdornment={Boolean(startAdornment)}
        endAdornment={Boolean(endAdornment)}
        {...props}
        onFocus={(evt: React.FocusEvent<HTMLInputElement>) => {
          setHasFocus(true);
          props.onFocus?.(evt);
        }}
        onBlur={(evt: React.FocusEvent<HTMLInputElement>) => {
          setHasFocus(false);
          props.onBlur?.(evt);
        }}
      />
      {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
    </FilledTextFieldBase>
  );
};
