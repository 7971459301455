import { styled } from 'styled-components';
import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import {
  FilledTextField,
  TextFieldProps,
  UnderlinedTextField,
} from '@/components/input/TextField';
import {
  CircleIconButton,
  IconButtonProps,
} from '@/components/input/IconButton';

const UnderlinedHidePasswordButton = styled(CircleIconButton).attrs({
  type: 'button',
})``;

const FilledHidePasswordButton = styled(CircleIconButton).attrs({
  type: 'button',
})`
  &:hover {
    background-color: ${({ theme }) => theme.palette.grayscale.light4};
  }
`;

type InternalPasswordFieldProps = {
  TextField: FC<TextFieldProps>;
  ToggleButton: FC<IconButtonProps>;
} & PasswordFieldProps;

const PasswordField: FC<InternalPasswordFieldProps> = ({
  TextField,
  ToggleButton,
  autoComplete = 'off',
  placeholder,
  ...props
}) => {
  const [isHovering, setIsHovering] = useState(0);
  const [hidden, setHidden] = useState(1);

  const toggleHidden = () => setHidden((hidden) => (hidden ? 0 : 1));

  return (
    <TextField
      type={hidden ? 'password' : 'text'}
      autoComplete={autoComplete}
      startAdornment={<FontAwesomeIcon icon={faLock} size="lg" />}
      endAdornment={
        <ToggleButton
          aria-label={hidden ? 'Show password' : 'Hide password'} // TODO translate
          onMouseEnter={() => setIsHovering(1)}
          onMouseLeave={() => setIsHovering(0)}
          disabled={props.disabled}
          onClick={toggleHidden}
          icon={
            <FontAwesomeIcon
              icon={isHovering ^ hidden ? faEyeSlash : faEye}
              size="lg"
            />
          }
        />
      }
      placeholder={hidden ? '●'.repeat(placeholder?.length ?? 8) : placeholder}
      valid={undefined}
      {...props}
    />
  );
};

export type PasswordFieldProps = TextFieldProps;

const UnderlinedPasswordField: FC<PasswordFieldProps> = (props) => (
  <PasswordField
    {...props}
    TextField={UnderlinedTextField}
    ToggleButton={UnderlinedHidePasswordButton}
  />
);

const FilledPasswordField: FC<PasswordFieldProps> = (props) => (
  <PasswordField
    {...props}
    TextField={FilledTextField}
    ToggleButton={FilledHidePasswordButton}
  />
);

export { UnderlinedPasswordField, FilledPasswordField };
