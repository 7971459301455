import { Scope, captureException } from '@sentry/core';
import { Axios } from '@/axios';

type Context = {
  error?: unknown;
  tags?: Record<string, string>;
};

function createError(event: unknown): [Error, Scope] {
  const scope = new Scope();

  if (event === undefined || event === null) {
    return [new Error(), scope];
  }

  if (typeof event === 'string') {
    return [new Error(event), scope];
  }

  if (event instanceof Error) {
    if (Axios.isError(event)) {
      scope.setExtra('Response message', Axios.extractErrorMessage(event));
      scope.setTags({
        'response.code': event.code ?? '',
        'response.status': event.status ?? '',
        'request.method': event.config?.method ?? '',
        'request.path': event.config?.url ?? '',
      });
    }

    return [event, scope];
  }

  return [new Error(JSON.stringify(event)), scope];
}

export const Sentry = {
  /**
   * Logs and reports the given error to Sentry.
   */
  reportError(name: string, context?: Context): Error {
    const [error, scope] = createError(context?.error);

    try {
      // Setting name may yield a TypeError depending on the Error implementation
      error.name = name;
    } catch {
      // Intentionally left empty
    }

    if (context?.tags) {
      scope.setTags(context.tags);
    }

    captureException(error, scope);

    return error;
  },
};
