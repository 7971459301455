/**
 * Returns an array containing the integers in the given (inclusive) range
 *
 * @example
 *  range(0, 3) -> [0, 1, 2, 3]
 *  range(3, 1) -> [3, 2, 1]
 *  range(1, 1) -> [1]
 */
export function range(from: number, to: number): number[] {
  const descending = from > to;
  const min = Math.min(from, to);
  const max = Math.max(from, to);
  const length = max - min + 1;

  return Array.from({ length }, (_, i) => {
    if (descending) {
      return max - i;
    } else {
      return i + min;
    }
  });
}

/**
 * Clamps the given value between a minimum and maximum
 *
 *  @example
 *   clamp(0, 1, 3) -> 1
 *   clamp(2, 3, 1) -> 2
 *   clamp(4, 3, 1) -> 3
 */
export function clamp(value: number, a: number, b: number): number {
  const min = Math.min(a, b);
  const max = Math.max(a, b);

  return Math.max(Math.min(value, max), min);
}
