import { styled, css, keyframes } from 'styled-components';
import {
  matchPath,
  useLocation,
  NavLink as RouterLink,
} from 'react-router-dom';
import React, { FC } from 'react';
import { Styling } from '@/styling';
import { flexGap } from '@/flexGap';
import { Typography } from '@/components/text/Typography';

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  // Necessary because parent has pointer-events: none;
  pointer-events: all;
`;

const peekInFromTop = keyframes({
  from: {
    transform: 'translateY(-100%)',
  },
  to: {
    transform: 'translateY(0%)',
  },
});

const ActiveLight = styled.div(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    width: '45px',
    height: '3px',
  }),
  css`
    animation: ${peekInFromTop} 100ms linear 1;
  `,
);

const List = styled.ul`
  display: flex;
  height: 100%;
  ${({ theme }) => flexGap(theme.spacing[2])};
`;

const ListItem = styled.li({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Link = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: ${Styling.spacing(0, 3)};
  color: #4d4d4d; // TODO move to theme
  transition:
    color 50ms,
    padding-bottom 50ms;
  white-space: nowrap;
  text-decoration: none;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.palette.primary.main};
    padding-bottom: ${Styling.spacing(1)};
  }
`;

export type Path = {
  href: string;
  title: string;
};

export type NavigationProps = {
  label?: string;
  paths: Path[];
};

export const HeaderNavigation: FC<NavigationProps> = ({
  label = 'navigation',
  paths,
}) => {
  const location = useLocation();

  return (
    <Nav aria-label={label}>
      <List>
        {paths.map((path) => {
          const match =
            matchPath(location.pathname, {
              path: path.href,
              exact: false,
              strict: false,
            }) !== null;

          return (
            <ListItem key={path.title}>
              {match && <ActiveLight />}
              <Link to={path.href}>
                <Typography type="h4" as="span">
                  {path.title}
                </Typography>
              </Link>
            </ListItem>
          );
        })}
      </List>
    </Nav>
  );
};
