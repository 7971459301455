import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { ConfirmDialog } from '@idk-web/core-ui';

export type LogoutDialogProps = {
  onOk?(): void;
  onCancel?(): void;
};

const LogoutDialog: FC<LogoutDialogProps> = (props) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      header={t('common.header.menu.log_out.are_you_sure')}
      okText={t('common.header.menu.log_out.confirm')}
      {...props}
    />
  );
};

export default LogoutDialog;
