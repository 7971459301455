import { client } from '@idk-web/api';
import store from '@/redux/store';
import { logout as logoutPortal } from '@/redux/portalAuth.slice';

const { VITE_BASE_URL = '' } = import.meta.env;

if (VITE_BASE_URL) {
  client.defaults.baseURL = VITE_BASE_URL;
}

client.interceptors.request.use(
  (config) => {
    if (config.headers?.Authorization) {
      return config;
    }

    const token = store.getState().portalAuth.token;

    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      config.headers!.Authorization = `Basic ${window.btoa(
        `${token}:${token}`,
      )}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch(logoutPortal());
    }

    return Promise.reject(error);
  },
);
