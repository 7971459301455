import { useSelector, PortalUser, useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { getOwnPortalUser } from '@idk-web/api';
import { selectPortalUser, updateUser } from '@/redux/portalAuth.slice';

export type User = PortalUser & {
  /**
   * Reloads the current user globally
   */
  reload(): Promise<void>;
};

/**
 * Returns the currently logged in portal user.
 *
 * This hook may only be used in components that are only mounted when a portal
 * user is guaranteed to be logged in.
 */
export function usePortalUser(): User {
  const dispatch = useDispatch();
  const portalUser = useSelector(selectPortalUser);

  const reload = useCallback(async () => {
    if (!portalUser) {
      console.warn('Tried to reload user when user is missing in state');
      return;
    }

    const user = await getOwnPortalUser();
    dispatch(updateUser(user));
  }, [portalUser]);

  return useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...portalUser!,
      reload,
    }),
    [portalUser, reload],
  );
}
