import { hostname } from '@idk-web/core-ui';

export type App = {
  /**
   * The runtime environment, based on the subdomain. Defaults to `prod` if undetermined.
   */
  env: 'dev' | 'stg' | 'prod';
  /**
   * The default language, based on the tld and browser language. Defaults to `en` if undetermined.
   */
  locale: string;
};

let env: App['env'];
let locale: App['locale'];

if (hostname.domain === 'localhost') {
  env = 'dev';
} else if (hostname.subdomain?.includes('dev')) {
  env = 'dev';
} else if (hostname.subdomain?.includes('stg')) {
  env = 'stg';
} else {
  env = 'prod';
}

switch (hostname.tld) {
  case 'se':
    locale = 'sv';
    break;
  case 'no':
    locale = 'no';
    break;
  case 'dk':
    locale = 'dk';
    break;
  case 'fi':
    locale = 'fi';
    break;
  default:
    locale = navigator.languages?.[0] ?? navigator.language ?? 'en';
    break;
}

const app: App = { env, locale };

export default app;
