export enum StorageType {
  LOCAL = 'localStorage',
  SESSION = 'sessionStorage',
}

/* eslint-disable @typescript-eslint/no-empty-function */
const NOOP_STORAGE: Storage = {
  length: 0,
  clear: () => {},
  getItem: () => null,
  key: () => null,
  removeItem: () => {},
  setItem: () => {},
};

export function isStorageSupported(type: StorageType) {
  const storage: Storage | undefined = window[type];

  if (!storage) {
    return false;
  }

  try {
    const key = 'support-check';
    storage.setItem(key, '');
    storage.removeItem(key);
    return true;
  } catch {
    return false;
  }
}

function getStorage(type: StorageType): Storage {
  if (isStorageSupported(type)) {
    return window[type];
  }

  console.warn(`${type} is not supported, using NOOP implementation`);

  return NOOP_STORAGE;
}

/**
 * A LocalStorage wrapper, which is NOOP if LocalStorage is unsupported.
 */
export const LocalStorage: Storage = getStorage(StorageType.LOCAL);

/**
 * A SessionStorage wrapper, which is NOOP if SessionStorage is unsupported.
 */
export const SessionStorage: Storage = getStorage(StorageType.SESSION);
