import { hostname } from '@idk-web/core-ui';

export const DEFAULT_NOTIFICATION_AUTO_HIDE_MS = 5000;
export const INFO_EMAIL = (() => {
  switch (hostname.tld) {
    case 'se':
      return 'info@idkollen.se';
    case 'no':
      return 'info@identisure.no';
    case 'dk':
      return 'info@identisure.dk';
    case 'fi':
      return 'info@identisure.fi';
    default:
      return 'info@identisure.com';
  }
})();
export const SITE_LINK = (() => {
  switch (hostname.tld) {
    case 'se':
      return 'https://idkollen.se';
    case 'no':
      return 'https://identisure.no';
    case 'dk':
      return 'https://identisure.dk';
    case 'fi':
      return 'https://identisure.fi';
    default:
      return 'https://identisure.com';
  }
})();
export const TRADEMARK = (() => {
  switch (hostname.tld) {
    case 'se':
      return 'IDkollen i Sverige AB';
    default:
      return 'Identisure';
  }
})();
