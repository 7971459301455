import { styled } from 'styled-components';
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';

const Button = styled.button.attrs({
  type: 'button',
  'aria-label': 'Open navigation menu', // TODO translate
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grayscale.light4};
  }
`;

export type HamburgerButtonProps = {
  onClick?(): void;
};

export const HamburgerButton: FC<HamburgerButtonProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <FontAwesomeIcon icon={faBars} size="lg" />
    </Button>
  );
};
