import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { setTag, setUser, withProfiler } from '@sentry/react';
import app from '@/utils/app';
import {
  selectPortalCompany,
  selectPortalUser,
} from '@/redux/portalAuth.slice';

const SentryProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { i18n } = useTranslation();
  const portalUser = useSelector(selectPortalUser);
  const portalCompany = useSelector(selectPortalCompany);

  useEffect(() => {
    setTag('idk.lang', i18n.language);
  }, [i18n]);

  useEffect(() => {
    setTag('idk.env', app.env);
  }, []);

  useEffect(() => {
    let user = null;

    if (portalUser && portalCompany) {
      user = {
        username: `PortalUser(id: ${portalUser.id}, company: ${portalCompany.id})`,
      };
    } else if (portalUser) {
      user = { username: `PortalUser(id: ${portalUser.id})` };
    }

    setUser(user);
  }, [portalUser, portalCompany]);

  return <>{children}</>;
};

export default withProfiler(SentryProvider);
