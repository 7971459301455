import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import {
  Styling,
  StandardCard,
  Box,
  DialogProps,
  OkButton,
  DangerButton,
} from '@idk-web/core-ui';

const DialogCard = styled(StandardCard)`
  border-top: 8px solid ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  max-width: 500px;
`;

const TextSection = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[2],
}))``;

const ButtonSection = styled(Box).attrs(({ theme }) => ({
  alignX: 'center',
  spacing: theme.spacing[4],
}))`
  margin-top: ${Styling.spacing(4)};
`;

const Header = styled.h2`
  ${Styling.typography('h2')};
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Text = styled.div`
  ${Styling.typography('body')};
  color: #888888; // TODO move to theme
`;

export type ConfirmDialogProps = {
  onOk?(): void;
  onCancel?(): void;
} & DialogProps;

const NewVersionDialog: FC<ConfirmDialogProps> = ({
  onOk,
  onCancel,
  onClose,
}) => {
  const { t } = useTranslation();

  const handleOk = () => {
    window.location.reload();
    onOk?.();
    onClose?.();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose?.();
  };

  return (
    <DialogCard>
      <TextSection>
        <Header>{t('common.new_version.header')}</Header>
        {<Text>{t('common.new_version.text')}</Text>}
      </TextSection>
      <ButtonSection>
        <DangerButton text={t('common.reject')} onClick={handleCancel} />
        <OkButton
          text={t('common.new_version.reload_button')}
          onClick={handleOk}
        />
      </ButtonSection>
    </DialogCard>
  );
};

export default NewVersionDialog;
