import { DefaultTheme, useTheme } from 'styled-components';
import { useMemo } from 'react';
import { useWindowSize } from '@/hooks/useWindowSize';

type BreakpointName = keyof DefaultTheme['breakpoints'];

export type Breakpoint = {
  above: boolean;
  below: boolean;
};

export function useBreakPoint(name: BreakpointName): Breakpoint {
  const theme = useTheme();
  const size = useWindowSize();
  const above = useMemo(
    () => size.width > theme.breakpoints[name],
    [size, theme, name],
  );
  const below = useMemo(
    () => size.width <= theme.breakpoints[name],
    [size, theme, name],
  );

  return useMemo(() => ({ above, below }), [above, below]);
}
