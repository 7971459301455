import { DefaultTheme, useTheme } from 'styled-components';
import ReactSelect, { StylesConfig } from 'react-select';
import { useTranslation } from 'react-i18next';
import React, { FC, ReactNode, useMemo } from 'react';

// There's a bug in React Select with Firefox where the dropdown menu can't be opened after being
// disabled when already focused. See https://github.com/JedWatson/react-select/issues/4906.
// `blurInputOnSelect` is added to all <ReactSelect/> inputs as a workaround.

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const dropdownRoot = document.getElementById('dropdown-root')!;

function getFilledSelectStyles(
  theme: DefaultTheme,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): StylesConfig<any, any, any> {
  return {
    container: (provided) => ({
      ...provided,
      pointerEvents: 'all',
      fontFamily: theme.typography.input.fontFamily,
      fontSize: theme.typography.input.fontSize,
      fontWeight: theme.typography.input.fontWeight,
      letterSpacing: theme.typography.input.letterSpacing,
      lineHeight: theme.typography.input.lineHeight,
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      margin: 0,
      borderRadius: `0 0 ${theme.radii.input} ${theme.radii.input}`,
      zIndex: theme.zIndex.dropdown,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: `${theme.spacing[1.5]} ${theme.spacing[1.5]}`,
      color: theme.palette.primary.text,
      backgroundColor: state.isFocused
        ? theme.palette.grayscale.light5
        : 'transparent',
    }),
    control: (provided, state) => ({
      ...provided,
      height: '53px',
      flexWrap: 'nowrap',
      padding: `${theme.spacing[0.75]} ${theme.spacing[0.5]}`,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: theme.palette.grayscale.light5,
      outline: state.isFocused
        ? `1px solid ${theme.palette.primary.main}`
        : '1px solid #E9E9E9', // TODO move to theme
      border: 0,
      borderRadius: state.menuIsOpen
        ? `${theme.radii.input} ${theme.radii.input} 0 0`
        : theme.radii.input,
      boxShadow: 'none',
      transition: 'none',
      '&:hover': {
        outline: state.isFocused
          ? `1px solid ${theme.palette.primary.main}`
          : '1px solid #B3B3B3',
        backgroundColor: state.isDisabled
          ? '#F9FAFB'
          : theme.palette.grayscale.light5,
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.palette.primary.text,
    }),
    input: (provided) => ({
      ...provided,
      color: theme.palette.primary.text,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.palette.primary.text,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px 4px',
    }),
    multiValue: (provided) => ({
      ...provided,
      border: '1px solid #E9E9E9',
      padding: '4px 0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    }),
  };
}

function getRoundSelectStyles(
  theme: DefaultTheme,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): StylesConfig<any, any, any> {
  return {
    container: (provided) => ({
      ...provided,
      pointerEvents: 'all',
      fontFamily: theme.typography.input.fontFamily,
      fontSize: theme.typography.input.fontSize,
      fontWeight: theme.typography.input.fontWeight,
      letterSpacing: theme.typography.input.letterSpacing,
      lineHeight: theme.typography.input.lineHeight,
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      margin: 0,
      borderRadius: `0 0 ${theme.radii.input} ${theme.radii.input}`,
      zIndex: theme.zIndex.dropdown,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: `${theme.spacing[1.5]} ${theme.spacing[1.5]}`,
      color: theme.palette.primary.text,
      backgroundColor: state.isFocused
        ? theme.palette.grayscale.light5
        : 'transparent',
    }),
    control: (provided, state) => ({
      ...provided,
      height: '53px',
      flexWrap: 'nowrap',
      padding: `${theme.spacing[0.75]} ${theme.spacing[0.5]}`,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: state.isDisabled
        ? 'rgba(255, 255, 255, 0.5)'
        : theme.palette.grayscale.white,
      outline: state.isFocused ? `1px solid ${theme.palette.primary.main}` : 0,
      border: 0,
      borderRadius: state.menuIsOpen
        ? `${theme.radii.input} ${theme.radii.input} 0 0`
        : theme.radii.input,
      boxShadow: 'none',
      transition: 'none',
      '&:hover': {
        outline: state.isFocused
          ? `1px solid ${theme.palette.primary.main}`
          : '1px solid #B3B3B3',
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isDisabled
        ? theme.palette.primary.text
        : theme.palette.secondary.main,
    }),
    input: (provided, state) => ({
      ...provided,
      color: state.isDisabled
        ? theme.palette.primary.text
        : theme.palette.secondary.main,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.palette.primary.text,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px 4px',
    }),
    multiValue: (provided) => ({
      ...provided,
      border: '1px solid #E9E9E9',
      padding: '4px 0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    }),
  };
}

export type SelectProps = {
  className?: string;
  name?: string;
  placeholder?: string;
  onChange?(value: string | null): void;
  value?: string;
  options: {
    label: ReactNode;
    value: string;
  }[];
  disabled?: boolean;
  loading?: boolean;
  canDeselect?: boolean;
  searchable?: boolean;
  noOptionsMessage?: ReactNode;
};

export const FilledSelect: FC<SelectProps> = ({
  className,
  name,
  placeholder,
  onChange,
  value,
  options,
  disabled,
  loading,
  canDeselect,
  searchable = false,
  noOptionsMessage,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getFilledSelectStyles(theme), [theme]);
  const { t } = useTranslation();
  options = useMemo(() => {
    if (canDeselect) {
      return [{ label: t('input.select'), value: '' }, ...options];
    }

    return options;
  }, [t, options]);

  return (
    <ReactSelect
      blurInputOnSelect
      isSearchable={searchable}
      className={className}
      styles={styles}
      name={name}
      placeholder={placeholder ?? t('input.select')}
      onChange={(item) => onChange?.(item?.value ?? null)}
      value={options.find((option) => option.value === value)}
      options={options}
      isDisabled={disabled || loading}
      isLoading={loading}
      noOptionsMessage={() => noOptionsMessage ?? t('input.no_options')}
      menuPortalTarget={dropdownRoot}
    />
  );
};

export const RoundSelect: FC<SelectProps> = ({
  className,
  name,
  placeholder,
  onChange,
  value,
  options,
  disabled,
  loading,
  canDeselect,
  searchable = false,
  noOptionsMessage,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getRoundSelectStyles(theme), [theme]);
  const { t } = useTranslation();
  options = useMemo(() => {
    if (canDeselect) {
      return [{ label: t('input.select'), value: '' }, ...options];
    }

    return options;
  }, [t, options]);

  return (
    <ReactSelect
      blurInputOnSelect
      isSearchable={searchable}
      className={className}
      styles={styles}
      name={name}
      placeholder={placeholder ?? t('input.select')}
      onChange={(item) => onChange?.(item?.value ?? null)}
      value={options.find((option) => option.value === value)}
      options={options}
      isDisabled={disabled || loading}
      isLoading={loading}
      noOptionsMessage={() => noOptionsMessage ?? t('input.no_options')}
      menuPortalTarget={dropdownRoot}
    />
  );
};

export type MultiSelectProps = {
  className?: string;
  name?: string;
  placeholder?: string;
  onChange?(values: string[]): void;
  values?: string[];
  options: {
    label: ReactNode;
    value: string;
  }[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  noOptionsMessage?: ReactNode;
};

export const FilledMultiSelect: FC<MultiSelectProps> = ({
  className,
  name,
  placeholder,
  onChange,
  values = [],
  options,
  disabled,
  loading,
  searchable = false,
  noOptionsMessage,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getFilledSelectStyles(theme), [theme]);
  const { t } = useTranslation();

  return (
    <ReactSelect
      blurInputOnSelect
      isMulti
      isSearchable={searchable}
      className={className}
      styles={styles}
      name={name}
      placeholder={placeholder ?? t('input.select')}
      onChange={(items) => onChange?.(items.map((item) => item.value))}
      value={options.filter((option) => values.includes(option.value))}
      options={options}
      isDisabled={disabled || loading}
      isLoading={loading}
      noOptionsMessage={() => noOptionsMessage ?? t('input.no_options')}
      menuPortalTarget={dropdownRoot}
    />
  );
};

export const RoundMultiSelect: FC<MultiSelectProps> = ({
  className,
  name,
  placeholder,
  onChange,
  values = [],
  options,
  disabled,
  loading,
  searchable = false,
  noOptionsMessage,
}) => {
  const theme = useTheme();
  const styles = useMemo(() => getRoundSelectStyles(theme), [theme]);
  const { t } = useTranslation();

  return (
    <ReactSelect
      blurInputOnSelect
      isMulti
      isSearchable={searchable}
      className={className}
      styles={styles}
      name={name}
      placeholder={placeholder ?? t('input.select')}
      onChange={(items) => onChange?.(items.map((item) => item.value))}
      value={options.filter((option) => values.includes(option.value))}
      options={options}
      isDisabled={disabled || loading}
      isLoading={loading}
      noOptionsMessage={() => noOptionsMessage ?? t('input.no_options')}
      menuPortalTarget={dropdownRoot}
    />
  );
};
