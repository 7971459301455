import { DefaultRootState, PortalAuthState } from 'react-redux';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PortalUser,
  Company as ApiCompany,
  AuthenticationResponse,
  PortalLoginResponse,
} from '@idk-web/api';

const initialState: PortalAuthState = {
  status: 'LOGGED_OUT',
  ref: null,
  token: null,
  user: null,
  currentCompany: null,
  availableCompanies: [],
  sso: undefined,
};

export const portalAuthSlice = createSlice({
  name: 'portal/auth',
  initialState: initialState as PortalAuthState,
  reducers: {
    logout(): PortalAuthState {
      return initialState;
    },
    login(
      state,
      action: PayloadAction<
        Extract<PortalLoginResponse, { status: 'COMPLETED' }>
      >,
    ): PortalAuthState {
      const payload = action.payload;

      return {
        status: 'LOGGED_IN',
        ref: payload.ref,
        token: null,
        user: {
          id: payload.user.id,
          username: payload.user.username,
          displayName: payload.user.displayName ?? '',
          email: payload.user.email,
          country: payload.user.country,
          mustChangePassword: payload.mustChangePassword,
          hasValidatedEmail: payload.hasValidatedEmail,
        },
        currentCompany: null,
        availableCompanies: payload.companies.map((company) => ({
          id: company.companyId,
          code: company.companyCode,
          name: company.companyName,
          isAdmin: company.isAdmin,
        })),
        sso: payload.sso,
      };
    },
    updateUser(state, action: PayloadAction<PortalUser>): void {
      if (state.user) {
        state.user.email = action.payload.email;
        state.user.mustChangePassword = action.payload.mustChangePassword;
        state.user.hasValidatedEmail = action.payload.hasValidatedEmail;
      }
    },
    authenticate(
      state,
      action: PayloadAction<{
        auth: AuthenticationResponse;
        config: ApiCompany;
      }>,
    ): PortalAuthState {
      const { auth, config } = action.payload;

      return {
        status: 'AUTHENTICATED',
        ref: state.ref ?? '',
        token: auth.token,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user: state.user!,
        currentCompany: {
          id: config.id,
          code: config.code,
          name: auth.company.name,
          isAdmin: auth.user.isAdmin,
          services: auth.user.availableServices,
          countries: config.countries.map((country) => ({
            teleidEnabled: country.teleidEnabled,
            teleidSsnEnabled: country.teleidSsnEnabled,
            teleidSsnNorwayEnabled: country.teleidSsnNorwayEnabled,
            teleidEmailEnabled: country.teleidEmailEnabled,
            teleidSmsEnabled: country.teleidSmsEnabled,
            teleidChatEnabled: country.teleidChatEnabled,
            teleidMaxUsersNumber: country.teleidMaxUsersNumber,
            teleidCounter: country.teleidCounter,
            telesignEnabled: country.telesignEnabled,
            telesignSsnEnabled: country.telesignSsnEnabled,
            telesignEmailEnabled: country.telesignEmailEnabled,
            telesignSmsEnabled: country.telesignSmsEnabled,
            telesignChatEnabled: country.telesignChatEnabled,
            telesignCounter: country.telesignCounter,
            telesignMaxUsersNumber: country.telesignMaxUsersNumber,
            safemailEnabled: country.safemailEnabled,
            safemailCounter: country.safemailCounter,
            safemailMaxUsersNumber: country.safemailMaxUsersNumber,
            country: country.country,
            defaultLanguage: country.defaultLanguage,
          })),
          defaultStartTab: auth.company.defaultStartTab,
          defaultBankIdCountry: auth.company.defaultCountry,
          logAccess: auth.user.canSeeLogs,
          telesignDefaultText: auth.company.telesignDefaultText,
          telesignDefaultTextDisabled: auth.company.telesignDefaultTextDisabled,
          formIdOnlySafemail: auth.company.formIdOnlySafemail,
          forms: config.forms.map((form) => ({
            id: form.id,
            name: form.formName,
            email: form.email,
            autodeleteCountDays: form.autodeleteCountDays ?? 180,
            country: form.country,
          })),
          requestSignatureEnabled: auth.company.requestSignatureEnabled,
          autodeleteCountDays: config.autodeleteCountDays,
          companyLogoUrl: auth.company.logoUrl,
          apiEnabled: config.apiEnabled,
          apiClientIp: config.apiClientIp?.split(',') ?? [],
          sentAccess: config.sentAccess,
          timezone: config.timezone,
          usernamePasswordNewAccountsDisabled:
            config.usernamePasswordNewAccountsDisabled,
          companySignatoryEnabled: config.companysignatory,
          apiKey: config.apiKey,
          apiToken: config.apiToken,
        },
        availableCompanies: state.availableCompanies,
        sso: state.sso,
      };
    },
  },
});

export const { logout, login, authenticate, updateUser } =
  portalAuthSlice.actions;

export const selectPortalLoginStatus = (state: DefaultRootState) =>
  state.portalAuth.status;
export const selectPortalUser = (state: DefaultRootState) =>
  state.portalAuth.user;
export const selectPortalCompany = (state: DefaultRootState) =>
  state.portalAuth.currentCompany;
export const selectAvailablePortalCompanies = createSelector(
  (state: DefaultRootState) => state.portalAuth.availableCompanies,
  (state: DefaultRootState) => state.portalAuth.currentCompany,
  (availableCompanies, currentCompany) => {
    return availableCompanies.filter(
      (company) => company.id !== currentCompany?.id,
    );
  },
);
export const selectPortalSso = (state: DefaultRootState) =>
  state.portalAuth.sso;

export default portalAuthSlice.reducer;
