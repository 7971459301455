import { AxiosError, AxiosResponse } from 'axios';
import { hasProperty } from '@/types';

export const Axios = {
  extractErrorMessage(error: AxiosError): string {
    const data = error.response?.data;

    if (typeof data === 'string') {
      return data;
    }

    if (data && typeof data === 'object') {
      if (hasProperty(data, 'error') && data.error) {
        return String(data.error);
      }

      if (hasProperty(data, 'message') && data.message) {
        return String(data.message);
      }
    }

    return `${error.message} ${error.code ?? error.status ?? ''}`;
  },

  isResponse(input: unknown): input is AxiosResponse<unknown> {
    if (!input || typeof input !== 'object') {
      return false;
    }

    return 'status' in input && 'statusText' in input;
  },

  is4xxResponse(response: unknown): response is AxiosResponse<unknown> {
    return (
      Axios.isResponse(response) &&
      response.status >= 400 &&
      response.status < 500
    );
  },

  isError(input: unknown): input is AxiosError {
    if (!input || typeof input !== 'object') {
      return false;
    }

    return 'config' in input && 'response' in input;
  },
};
