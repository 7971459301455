import { styled } from 'styled-components';
import React, { FC, PropsWithChildren } from 'react';
import { Styling } from '@idk-web/core-ui';

const Fields = styled.div`
  display: grid;
  grid-template-columns: auto minmax(50%, 1fr);
  gap: ${Styling.spacing(6)};
  @media (max-width: ${Styling.breakpoint('md')}px) {
    grid-template-columns: 1fr;
    gap: ${Styling.spacing(2)};
  }
`;

export type FormFieldsProps = PropsWithChildren<{}>;

const FormFields: FC<FormFieldsProps> = ({ children }) => {
  return <Fields>{children}</Fields>;
};

export default FormFields;
