import {
  DefaultTheme,
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import React, { FC, PropsWithChildren } from 'react';
import { Styling } from '@idk-web/core-ui';
import OpenSansLatinExt from '@/assets/fonts/opensans_latin_ext.woff2';
import OpenSansLatin from '@/assets/fonts/opensans_latin.woff2';
import OpenSansItalicLatinExt from '@/assets/fonts/opensans_italic_latin_ext.woff2';
import OpenSansItalicLatin from '@/assets/fonts/opensans_italic_latin.woff2';

const Reset = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    min-width: 0;
    min-height: 0;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: inherit;
  }

  ol, ul {
    list-style: none;
  }
`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(${OpenSansItalicLatinExt}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(${OpenSansItalicLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(${OpenSansLatinExt}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(${OpenSansLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(${OpenSansLatinExt}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(${OpenSansLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(${OpenSansLatinExt}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(${OpenSansLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  html {
    font-size: 17px;
    font-family: "OpenSans", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  }

  .sentry-error-embed-wrapper {
    z-index: ${Styling.zIndex('dialog')} !important;
  }
`;

const theme: DefaultTheme = {
  palette: {
    primary: {
      main: '#F7A700',
      light1: '#F9B834',
      light2: '#FACA67',
      light3: '#FCDB99',
      light4: '#FDEDCC',
      light5: '#F9f1E0',
      text: '#4D4D4D',
    },
    secondary: {
      main: '#425466',
      light1: '#687685',
      light2: '#8E98A3',
      light3: '#B3BBC2',
      light4: '#D9DDE0',
      text: '#FFFFFF',
    },
    grayscale: {
      main: '#808080',
      light1: '#999999',
      light2: '#B3B3B3',
      light3: '#CCCCCC',
      light4: '#E6E6E6',
      light5: '#F4F4F4',
      white: 'white',
      dark1: '#747474',
      dark2: '#4D4D4D',
      dark3: '#333333',
    },
    error: {
      main: '#B85050',
      light1: '#D45C5C',
      dark1: '#963D3D',
      text: 'white',
    },
    warning: {
      main: '#ff8c69',
      text: 'white',
    },
    info: {
      main: '#3f6ed8',
      text: 'white',
    },
    success: {
      main: '#178610',
      light1: '#1FA117',
      dark1: '#116B0B',
      text: 'white',
    },
    disabled: {
      main: '#F0F0F0',
      dark1: '#D9DDE0',
      text: '#8E98A3',
    },
  },
  shadows: {
    card: '0px 0px 6px 6px rgba(0, 0, 0, 0.02)',
    header: '0px 0px 1px 1px #E6E6E6',
    drawer: '0px 0px 40px 10px #151A2D5C',
    dropdown: '0px 4px 24px 6px #D4DAE766',
    dialog: '0px 8px 40px 10px #151A2D5C',
    notification: '0px 8px 40px 10px #151A2D5C',
    tooltip: '0px 4px 32px 8px #D4DAE766',
  },
  typography: {
    h1: {
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '30px',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '40px',
    },
    h2: {
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '30px',
    },
    h3: {
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '20px',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
    },
    h4: {
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '18px',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '22px',
    },
    h5: {
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '22px',
    },
    body: {
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '22px',
    },
    button: {
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '14px',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '18px',
    },
    input: {
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '18px',
    },
    caption: {
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '12px',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '22px',
    },
  },
  breakpoints: {
    xs: 380,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
  spacing: {
    0: '0px',
    0.25: '2px',
    0.5: '4px',
    0.75: '6px',
    1: '8px',
    1.5: '12px',
    2: '16px',
    3: '24px',
    4: '32px',
    5: '40px',
    6: '48px',
    7: '56px',
    8: '64px',
    9: '72px',
  },
  radii: {
    0: '0px',
    card: '4px',
    dialog: '4px',
    button: '4px',
    input: '4px',
    radioButton: '6px',
    notification: '4px',
  },
  zIndex: {
    header: 1000,
    dropdown: 1100,
    tooltip: 1200,
    backdrop: 1300,
    drawer: 1400,
    dialog: 1500,
    notification: 1600,
  },
};

export const ThemeProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <StyledThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
};
