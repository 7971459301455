import { styled, keyframes } from 'styled-components';
import { createPortal } from 'react-dom';
import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';
import { Styling } from '@/styling';
import { Backdrop } from '@/components/visual/Backdrop';
import { Box } from '@/components/layout/Box';

export type DialogProps = {
  onClose?(): void;
};

export type DialogComponent = FC<DialogProps>;

export type DialogOptions = {
  /**
   * Determines whether the dialog window should be closed when the user clicks
   * outside of it.
   *
   * Defaults to `true` if not specified.
   */
  closeOnBackgroundClick?: boolean;
  /**
   * Determines whether the dialog window should be closed when the user presses
   * the Escape button.
   *
   * Defaults to `true` if not specified.
   */
  closeOnEscape?: boolean;
};

export type DialogHandle = {
  /**
   * Closes the dialog window.
   */
  close(): void;
};

type Dialog = {
  component: DialogComponent;
  options: DialogOptions;
};

export type DialogContext = {
  show(component: DialogComponent, options?: DialogOptions): DialogHandle;
};

const DialogsContext = createContext<DialogContext | null>(null);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const dialogRoot = document.getElementById('dialog-root')!;

const popUp = keyframes`
  from {
    transform: translate(-50%, calc(-50% + 10px));
  }
  to {
    transform: translate(-50%, -50%);
  }
`;

const FADE_DURATION_MS = 100;

const Container = styled(Box).attrs({
  direction: 'horizontal',
  alignX: 'center',
})<{ show: boolean }>`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  padding: ${Styling.spacing(2)};
  transform: translate(-50%, -50%);
  z-index: ${Styling.zIndex('dialog')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  animation: ${popUp} ${FADE_DURATION_MS}ms linear 1;
  transition: opacity ${FADE_DURATION_MS}ms;
`;

export const DialogProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [dialogs, setDialogs] = useState<Dialog[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const context = useMemo<DialogContext>(
    () => ({
      show(component, options) {
        const dialog = { component, options: options ?? {} };

        setDialogs((dialogs) => [...dialogs, dialog]);

        return {
          close() {
            handleClose(dialog);
          },
        };
      },
    }),
    [],
  );
  const dialog = useMemo(() => {
    if (dialogs.length === 0) {
      return null;
    }

    return dialogs[dialogs.length - 1];
  }, [dialogs]);

  useEffect(() => {
    setIsOpen(dialogs.length > 0);
  }, [dialogs]);

  useEffect(() => {
    const handleKeyDown = (evt: KeyboardEvent) => {
      if (
        evt.key === 'Escape' &&
        dialog &&
        dialog.options.closeOnEscape !== false
      ) {
        handleClose(dialog);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [dialog]);

  const handleClose = (dialog: Dialog): void => {
    const doClose = (): void => {
      setDialogs((dialogs) => {
        const copy = dialogs.slice();
        const index = copy.indexOf(dialog);

        if (index > -1) {
          copy.splice(index, 1);
        }

        return copy;
      });
    };

    if (dialogs.length <= 1) {
      doClose();
    } else {
      setIsOpen(false);
      setTimeout(doClose, FADE_DURATION_MS + 24);
    }
  };

  const handleBackdropClick = () => {
    if (dialog && dialog.options.closeOnBackgroundClick !== false) {
      handleClose(dialog);
    }
  };

  return (
    <DialogsContext.Provider value={context}>
      {children}
      {createPortal(
        <Backdrop
          show={dialogs.length > 0}
          durationMs={FADE_DURATION_MS}
          onClick={handleBackdropClick}
        >
          <Container
            onClick={(e) => {
              // Close if direct click on element
              if (e.currentTarget === e.target) {
                handleBackdropClick();
              }
            }}
            show={isOpen}
          >
            {dialog && <dialog.component onClose={() => handleClose(dialog)} />}
          </Container>
        </Backdrop>,
        dialogRoot,
      )}
    </DialogsContext.Provider>
  );
};

export function useDialog(): DialogContext {
  const context = useContext(DialogsContext);

  if (!context) {
    throw new Error('useDialog hook must be used within a DialogsProvider');
  }

  return context;
}
