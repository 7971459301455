import { styled } from 'styled-components';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Styling, flexGap } from '@idk-web/core-ui';

const InfoColumn = styled.div<{ centered: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.centered ? 'center' : 'start')};
  ${({ theme }) => flexGap(theme.spacing[1])};
  max-width: 400px;
  word-wrap: break-word;
`;

const FieldColumn = styled.div`
  width: 100%;
`;

const Title = styled.h4`
  ${Styling.typography('h4')};
  color: ${({ theme }) => theme.palette.primary.text};
`;

const Description = styled.div`
  ${Styling.typography('body')};
  color: ${({ theme }) => theme.palette.primary.text};
`;

const Required = styled.span`
  color: #b85050;
`;

type FieldSectionProps = PropsWithChildren<{
  title?: ReactNode;
  description?: ReactNode;
  required?: boolean;
  centered?: boolean;
}>;

const FieldContainer: FC<FieldSectionProps> = ({
  title,
  description,
  required = false,
  centered = false,
  children,
}) => {
  return (
    <>
      <InfoColumn centered={centered}>
        <Title>
          {title}
          {required && <Required> * </Required>}
        </Title>
        {description && <Description>{description}</Description>}
      </InfoColumn>
      <FieldColumn>{children}</FieldColumn>
    </>
  );
};

export default FieldContainer;
