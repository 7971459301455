import { DependencyList, useCallback, useEffect, useState } from 'react';
import { useIsMounted } from '@idk-web/core-ui';
import { getVersionHeaders } from '@idk-web/api';

const INTERVAL = 5 * 60 * 1000;

const initial = Date.now();

/**
 * Calls the given callback function when a new version of this application
 * is available.
 */
function useNewVersionAvailable(func: () => void, deps: DependencyList): void {
  const isMounted = useIsMounted();
  const [current, setCurrent] = useState(initial);
  const check = useCallback(async () => {
    const headers = await getVersionHeaders();
    const lastModified = headers['last-modified'];

    if (lastModified && isMounted()) {
      const latest = Date.parse(lastModified);

      if (latest > current) {
        func();
      }

      setCurrent(latest);
    }
  }, [current]);

  useEffect(() => {
    const id = setInterval(check, INTERVAL);

    return () => clearInterval(id);
  }, [check, ...deps]);
}

export default useNewVersionAvailable;
