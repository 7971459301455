import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC, ReactNode } from 'react';
import { Styling } from '@/styling';
import { DialogProps } from '@/hooks/useDialog';
import { Box } from '@/components/layout/Box';
import { OkButton, DangerButton } from '@/components/input/Button';
import { StandardCard } from '@/components/card/standard';

const DialogCard = styled(StandardCard)`
  border-top: 8px solid ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  max-width: 500px;
`;

const TextSection = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[2],
}))``;

const ButtonSection = styled(Box).attrs(({ theme }) => ({
  alignX: 'center',
  spacing: theme.spacing[4],
}))`
  margin-top: ${Styling.spacing(4)};
`;

const Header = styled.h2`
  ${Styling.typography('h2')};
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Text = styled.div`
  ${Styling.typography('body')};
  color: #888888; // TODO move to theme
`;

export type ConfirmDialogProps = {
  header: ReactNode;
  text?: ReactNode;
  okText?: string;
  cancelText?: string;
  onOk?(): void;
  onCancel?(): void;
} & DialogProps;

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  header,
  text,
  okText,
  cancelText,
  onOk,
  onCancel,
  onClose,
}) => {
  const { t } = useTranslation();

  const handleOk = () => {
    onOk?.();
    onClose?.();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose?.();
  };

  return (
    <DialogCard>
      <TextSection>
        <Header>{header}</Header>
        {text && <Text>{text}</Text>}
      </TextSection>
      <ButtonSection>
        <DangerButton
          text={cancelText ?? t('common.reject')}
          onClick={handleCancel}
        />
        <OkButton text={okText ?? t('common.confirm')} onClick={handleOk} />
      </ButtonSection>
    </DialogCard>
  );
};
