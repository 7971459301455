import { styled } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import React, { FC, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { Styling } from '@/styling';
import { ResponsiveImage } from '@/components/visual/ResponsiveImage';
import { Typography } from '@/components/text/Typography';
import { Drawer, DrawerProps } from '@/components/navigation/Drawer';

const Container = styled(Drawer)`
  width: 100%;
  max-width: 420px;
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    max-width: initial;
  }
`;

const List = styled.ul``;

const ListItem = styled.li``;

const Link = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4d4d4d; // TODO move to theme
  height: 100%;
  padding: ${Styling.spacing(3, 5)};
  transition: color 50ms;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grayscale.light4};
  }

  &:hover,
  &.active {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Button = styled.button.attrs({
  type: 'button',
  'aria-label': 'Close navigation menu', // TODO translate
})`
  display: flex;
  position: fixed;
  right: 0;
  margin: 12px;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grayscale.light4};
  }
`;

export type HamburgerMenuProps = {
  onClick?(): void;
};

const Logo = styled(ResponsiveImage).attrs({
  alt: 'Logo', // TODO translate
})`
  max-width: 160px;
  max-height: 120px;
  padding: ${Styling.spacing(3, 0, 2, 5)};
`;

const CloseButton: FC<HamburgerMenuProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <FontAwesomeIcon icon={faXmark} size="lg" />
    </Button>
  );
};

export type MenuItem = {
  title: string;
  href: string;
  icon?: ReactNode;
};

export type MobileMenuProps = {
  logo?: string;
  items: MenuItem[];
} & DrawerProps;

export const MobileNavDrawer: FC<MobileMenuProps> = ({
  logo,
  open,
  onClose,
  items,
}) => (
  <Container open={open} onClose={onClose}>
    <CloseButton onClick={onClose} />
    {logo && <Logo src={logo} />}
    <List>
      {items.map((item) => (
        <ListItem key={item.title}>
          <Link to={item.href} onClick={onClose}>
            {item.icon}
            <Typography type="h4">{item.title}</Typography>
          </Link>
        </ListItem>
      ))}
    </List>
  </Container>
);
