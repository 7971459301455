import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC, useMemo, useState } from 'react';
import {
  isStorageSupported,
  StorageType,
  Styling,
  isFlexGapSupported,
  Box,
  OkButton,
} from '@idk-web/core-ui';

const Container = styled(Box).attrs(({ theme }) => ({
  alignX: 'center',
  spacing: theme.spacing[2],
}))`
  position: relative;
`;

const Content = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[2],
}))`
  padding: ${Styling.spacing(2)};
  border: 1px dashed ${({ theme }) => theme.palette.grayscale.light2};
  background-color: ${({ theme }) => theme.palette.grayscale.light5};
  text-align: center;
`;

const Header = styled.div`
  ${Styling.typography('h4')};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const Text = styled.div`
  ${Styling.typography('body')};
  color: ${({ theme }) => theme.palette.primary.text};
`;

const BrowserSupportMessage: FC = () => {
  const { t } = useTranslation();
  const isSupported = useMemo(() => {
    // Not supported in Safari <14.1, required for most spacing.
    const flexGapSupported = isFlexGapSupported();

    // Not supported in some mobile inline browsers (for example gmail & facebook), required for state persistence
    // and callback-based logins (norway, denmark, finland).
    const localStorageSupported = isStorageSupported(StorageType.LOCAL);

    return flexGapSupported && localStorageSupported;
  }, []);
  const [open, setOpen] = useState(!isSupported);

  if (!open) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Header>{t('support.notification.header')}</Header>
        <Text>{t('support.notification.text')}</Text>
        <Box alignX="center">
          <OkButton text={t('common.ok')} onClick={() => setOpen(false)} />
        </Box>
      </Content>
    </Container>
  );
};

export default BrowserSupportMessage;
