import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import {
  Styling,
  StandardCard,
  Typography,
  Box,
  DialogProps,
  DangerButton,
} from '@idk-web/core-ui';

const DialogCard = styled(StandardCard)`
  border-top: 8px solid ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  max-width: 500px;
`;

const TextSection = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  alignX: 'center',
  spacing: theme.spacing[2],
}))``;

const ButtonSection = styled(Box).attrs(({ theme }) => ({
  alignX: 'center',
  spacing: theme.spacing[4],
}))`
  margin-top: ${Styling.spacing(4)};
`;

const Header = styled(Typography).attrs<{ type?: string }>({
  type: 'h2',
})`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Text = styled(Typography).attrs<{ type?: string }>({
  type: 'body',
})`
  color: #888888; // TODO move to theme
`;

export type MultiUserMaxUserDialogProps = {
  onConfirm?(): void;
  onCancel?(): void;
} & DialogProps;

const MultiUserMaxUserDialog: FC<MultiUserMaxUserDialogProps> = ({
  onConfirm,
  onCancel,
  onClose,
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm?.();
    onClose?.();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose?.();
  };

  return (
    <DialogCard>
      <TextSection>
        <Header>{t('login.max_users.title')}</Header>
        <Text>{t('login.max_users.text')}</Text>
      </TextSection>
      <ButtonSection>
        <DangerButton text={t('common.cancel')} onClick={handleCancel} />
        <DangerButton text={t('common.confirm')} onClick={handleConfirm} />
      </ButtonSection>
    </DialogCard>
  );
};

export default MultiUserMaxUserDialog;
