import { styled } from 'styled-components';
import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@idk-web/core-ui';
import Header from '@/components/portal/header/Header';
import Footer from '@/components/portal/footer/Footer';

const Container = styled(Box).attrs({
  direction: 'vertical',
})`
  min-height: 100vh;
`;

const Main = styled.main`
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.grayscale.light5};
`;

const Layout: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Container>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
};

export default Layout;
