import { useEffect, useState } from 'react';

/**
 * A hook that returns the current document state. This hook reacts to
 * visibilitychange events.
 */
export function useVisibility(): DocumentVisibilityState {
  const [visibility, setVisibility] = useState<DocumentVisibilityState>(
    document.visibilityState,
  );

  useEffect(() => {
    const handleVisibilityChange = () =>
      setVisibility(document.visibilityState);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return visibility;
}
