import { client } from '@/client';

export type ScheduledMaintenance = {
  subject: string;
  body: string;
};

export async function getScheduledMaintenance(
  signal?: AbortSignal,
): Promise<ScheduledMaintenance | null> {
  return client
    .get<ScheduledMaintenance | null>('/scheduled-maintenance/upcoming', {
      signal,
    })
    .then((response) => response.data);
}
