const routes = {
  login: '/login',
  loginSso: '/login/sso/:registrationId',
  sessionExpiredSso: '/login/sso/:registrationId/expired',
  loginCallback: '/callback/:country?',
  loginSsoCallback: '/sso/callback',
  home: '/',
  teleid: '/teleid',
  telesign: '/telesign',
  safemail: '/safemail',
  safemailInbox: '/safemail/inbox',
  safemailNew: '/safemail/new',
  safemailOutbox: '/safemail/sent',
  admin: '/admin',
  adminUsers: '/admin/users',
  adminAddUser: '/admin/users/add',
  adminEditUser: '/admin/users/edit/:id',
  adminAdmins: '/admin/admins',
  adminTeleID: '/admin/teleid',
  adminTeleSign: '/admin/telesign',
  adminSafeMail: '/admin/safemail',
  profile: '/profile',
  validateEmail: '/email/validate',
  passwordReset: '/password/reset',
};

export default routes;
