import { styled } from 'styled-components';
import { createPortal } from 'react-dom';
import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
  PropsWithChildren,
  ReactNode,
} from 'react';
import {
  Notification,
  NotificationType,
} from '@/components/visual/Notification';
import { Box } from '@/components/layout/Box';

export type NotificationHandle = {
  /**
   * Closes the notification.
   */
  close(): void;
};

type Notification = {
  key: string;
  content: ReactNode;
  type: NotificationType;
  timeout?: number;
};

export type NotificationContext = {
  show(
    message: ReactNode,
    type: NotificationType,
    timeout?: number,
  ): NotificationHandle;
};

const NotificationsContext = createContext<NotificationContext | null>(null);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const notificationRoot = document.getElementById('notification-root')!;

let currentId = 0;

const Container = styled(Box).attrs(({ theme }) => ({
  direction: 'vertical',
  spacing: theme.spacing[2],
}))(({ theme }) => ({
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: 'auto',
  padding: theme.spacing[2],
  zIndex: theme.zIndex.notification,
  [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
    width: '100%',
  },
}));

export const NotificationProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const context = useMemo<NotificationContext>(
    () => ({
      show(content, type, timeout) {
        const key = String(currentId++);
        const notification = { key, content, type, timeout };

        setNotifications((notifications) => [...notifications, notification]);

        return {
          close() {
            handleClose(notification);
          },
        };
      },
    }),
    [],
  );
  const components = useMemo(
    () =>
      notifications.map((notification) => (
        <Notification
          open
          key={notification.key}
          onClose={() => handleClose(notification)}
          type={notification.type}
          autoHideDurationMs={notification.timeout}
        >
          {notification.content}
        </Notification>
      )),
    [notifications],
  );

  const handleClose = (notification: Notification): void => {
    setNotifications((notifications) => {
      const copy = notifications.slice();
      const index = copy.indexOf(notification);

      if (index > -1) {
        copy.splice(index, 1);
      }

      return copy;
    });
  };

  return (
    <NotificationsContext.Provider value={context}>
      {children}
      {components.length > 0 &&
        createPortal(<Container>{components}</Container>, notificationRoot)}
    </NotificationsContext.Provider>
  );
};

export function useNotification(): NotificationContext {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      'useNotification hook must be used within a NotificationsProvider',
    );
  }

  return context;
}
